import { ChangeEvent, FunctionComponent } from 'react';

import { IAblauf } from '@shared/interfaces';
import SectionBlock from '../Common/SectionBlock';
import EmailRecipient from '../Common/EmailRecipient';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';

interface ILocationOnlineSettings {
  ablauf: IAblauf[];
  tool: string;
  isClientTool: boolean;
}

const LocationOnlineForm: FunctionComponent<ILocationOnlineSettings> = () => {
  const { eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();
  const { t } = useTranslation();

  if (!eventSettings) return <></>;

  const { sendToParticipants } = eventSettings.location ?? {};

  const handleChange = (_inputEvent: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    eventSettingsDispatch({
      type: 'update',
      payload: {
        location: {
          sendToParticipants: checked,
        },
      },
    });
  };

  return (
    <SectionBlock>
      <EmailRecipient
        title={t('eventDetails.settings.tabs.location.email.emailRecipientsLabel')}
        hintText={<Trans i18nKey={'eventDetails.settings.tabs.location.email.recipientEmailHintWrite'} />}
        section="location"
        requiredInput
      />
      <FormControlLabel
        control={<Checkbox onChange={handleChange} checked={sendToParticipants === true} />}
        label={t('eventDetails.settings.tabs.location.email.sendToParticipants')}
      />
    </SectionBlock>
  );
};

export default LocationOnlineForm;
