import { IAddress, SeminarDocumentsFormat } from '@shared/interfaces';
import { ChangeEvent, FunctionComponent } from 'react';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import { DefaultLabel } from '../Common/DefaultLabel';
import EmailRecipient from '../Common/EmailRecipient';
import SectionBlock from '../Common/SectionBlock';
import AddressMultiForm, { ISelectableAddress } from '../Common/AddressMultiForm';
import { resolveAddress } from '../../../../utils/address-resolver';

export const showAddressForm = (
  format: SeminarDocumentsFormat | undefined,
  additionalDocuments: boolean,
  sendWritingMaterial: boolean
) => {
  return format === 'print' || additionalDocuments || sendWritingMaterial;
};

const SeminarDocumentsOnsiteForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const { eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();

  if (!eventSettings) return <></>;

  const { additionalDocuments, format, recipientAddress, sendWritingMaterials, sendToParticipants } =
    eventSettings.seminarDocuments ?? {};

  const addressHintTranslationKeySuffix = recipientAddress ? 'customAddress' : 'debitorAddress';

  const contactDataAddress = resolveAddress(eventSettings, 'contactData');
  const locationAddress = resolveAddress(eventSettings, 'location');

  const handleChange = (_inputEvent: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    eventSettingsDispatch({
      type: 'update',
      payload: {
        seminarDocuments: {
          sendToParticipants: checked,
        },
      },
    });
  };

  const selectableContactDataAddress: ISelectableAddress | undefined = contactDataAddress && {
    address: contactDataAddress,
    key: 'contactData',
    label: t('eventDetails.settings.tabs.contactData.customerAddress'),
  };

  const selectableLocationAddress: ISelectableAddress | undefined = locationAddress && {
    address: locationAddress,
    key: 'location',
    label: t('eventDetails.settings.tabs.location.label'),
  };

  const selectableAddresses: ISelectableAddress[] = [selectableContactDataAddress, selectableLocationAddress].filter(
    (item): item is ISelectableAddress => typeof item !== 'undefined'
  );

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.formatLabel')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.formatHint')}
        />
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-buttons-seminar-documents-format"
            value={typeof format !== 'undefined' ? format : null}
            name="radio-buttons-location"
            onChange={(event) =>
              eventSettingsDispatch({
                type: 'update',
                payload: {
                  seminarDocuments: {
                    sendWritingMaterials: undefined,
                    format: event.target.value as SeminarDocumentsFormat,
                  },
                },
              })
            }
          >
            <FormControlLabel
              key="radio-buttons-seminar-documents-format-digital"
              value="digital"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.seminarDocuments.formats.digital')}
            />
            <FormControlLabel
              key="radio-buttons-seminar-documents-format-print"
              value="print"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.seminarDocuments.formats.print')}
            />
            <FormControlLabel
              key="radio-buttons-seminar-documents-format-noSu"
              value="noSU"
              control={<Radio />}
              label={t('eventDetails.settings.tabs.seminarDocuments.formats.noSU')}
            />
          </RadioGroup>
        </FormControl>
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.additionalDocuments.label')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.additionalDocumentsHint')}
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={additionalDocuments ?? false}
                onChange={(_event, checked: boolean) =>
                  eventSettingsDispatch({
                    type: 'update',
                    payload: { seminarDocuments: { additionalDocuments: checked } },
                  })
                }
              />
            }
            label={t('eventDetails.settings.tabs.seminarDocuments.additionalDocuments.additionalDocumentsCheckbox')}
          />
          <RadioGroup
            aria-labelledby="radio-buttons-seminar-documents-additionalDocuments"
            value={additionalDocuments}
            name="radio-buttons-additionalDocuments"
            onChange={(event) =>
              eventSettingsDispatch({
                type: 'update',
                payload: { seminarDocuments: { additionalDocuments: event.target.value === 'true' } },
              })
            }
          />
        </FormControl>
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterialsLabel')}
          alertText={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterialsHint')}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={format === 'digital'}
              checked={sendWritingMaterials ?? false}
              onChange={(_event, checked: boolean) =>
                eventSettingsDispatch({
                  type: 'update',
                  payload: { seminarDocuments: { sendWritingMaterials: checked } },
                })
              }
            />
          }
          label={t('eventDetails.settings.tabs.seminarDocuments.sendWritingMaterials.sendWritingMaterialsCheckbox')}
        />
      </SectionBlock>

      {format === 'digital' && (
        <SectionBlock>
          <EmailRecipient
            title={t('eventDetails.settings.tabs.seminarDocuments.emailRecipientsLabel')}
            hintText={<Trans i18nKey={'eventDetails.settings.tabs.seminarDocuments.recipientEmailHintWrite'} />}
            section="seminarDocuments"
            requiredInput
          />
          <FormControlLabel
            control={<Checkbox onChange={handleChange} checked={sendToParticipants === true} />}
            label={t('eventDetails.settings.tabs.seminarDocuments.email.sendToParticipants')}
          />
        </SectionBlock>
      )}

      {showAddressForm(format, !!additionalDocuments, !!sendWritingMaterials) && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t('eventDetails.settings.tabs.seminarDocuments.recipientAddressLabel')}
            alertText={t(
              `eventDetails.settings.tabs.seminarDocuments.recipientAddressHint.${addressHintTranslationKeySuffix}`
            )}
          />
          <AddressMultiForm
            dialogTitle={t('eventDetails.settings.tabs.seminarDocuments.recipientAddressLabel')}
            customAddress={recipientAddress}
            onChangeCustomAddress={(address: Partial<IAddress> | undefined) =>
              eventSettingsDispatch({
                type: 'update',
                payload: { seminarDocuments: { recipientAddress: address, recipientAddressRef: 'seminarDocuments' } },
              })
            }
            selectableAddresses={selectableAddresses}
          />
        </SectionBlock>
      )}
    </>
  );
};

export default SeminarDocumentsOnsiteForm;
