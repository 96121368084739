import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import { useEventSettings as useEventSettingsContext } from '../../Context/EventSettingsContext';
import ContactDataSummary from './ContactData/ContactDataSummary';
import Submit from './Submit';
import TrainerAccommodationSummary from './TrainerAccommodation/TrainerAccommodationSummary';
import RoomSetupSummary from './RoomSetup/RoomSetupSummary';
import ConfirmationOfParticipationSummary from './ConfirmationOfParticipation/ConfirmationOfParticipationSummary';
import FeedbackSummary from './Feedback/FeedbackSummary';
import LocationOnlineSummary from './Location/LocationOnlineSummary';
import LocationOnsiteSummary from './Location/LocationOnsiteSummary';
import SeminarDocumentsOnlineSummary from './SeminarDocuments/SeminarDocumentsOnlineSummary';
import SeminarDocumentsOnsiteSummary from './SeminarDocuments/SeminarDocumentsOnsiteSummary';
import ParticipantsSummary from './Participants/ParticipantsSummary';
import SectionBlock from './Common/SectionBlock';
import { SummaryLabel } from './Common/SummaryLabel';
import { ReadonlyField } from './Common/ReadonlyField';
import { useTranslation } from 'react-i18next';
import SectionSummary from './Common/SectionSummary';

const Summary: FunctionComponent = () => {
  const { eventSettings, event } = useEventSettingsContext();

  const { t } = useTranslation();

  if (!eventSettings || !event) {
    return <></>;
  }

  return (
    <Stack spacing={3}>
      <ContactDataSummary />
      {event.isOnline ? <LocationOnlineSummary /> : <LocationOnsiteSummary />}
      {!event.isOnline && <TrainerAccommodationSummary />}
      {!event.isOnline && <RoomSetupSummary />}
      {event.isOnline ? <SeminarDocumentsOnlineSummary /> : <SeminarDocumentsOnsiteSummary />}
      <ConfirmationOfParticipationSummary />
      <ParticipantsSummary />
      <FeedbackSummary />
      <SectionSummary title={t(`eventDetails.settings.tabs.summary.finalInformation.header`)}>
        <SectionBlock>
          <SummaryLabel
            label={t('eventDetails.settings.tabs.summary.finalInformation.label')}
            copyText={eventSettings.additionalFinalInformation ? eventSettings.additionalFinalInformation : ''}
          />
          {eventSettings.additionalFinalInformation ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
        </SectionBlock>
      </SectionSummary>
      {!['SENT_TO_CUSTOMER', 'FINISHED_BY_CUSTOMER'].includes(eventSettings.status) && <Submit />}
    </Stack>
  );
};

export default Summary;
