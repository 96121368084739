import { FunctionComponent } from 'react';
import SectionSummary from '../Common/SectionSummary';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useTranslation } from 'react-i18next';
import { IEventSettingsData } from '@shared/interfaces';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import { SummaryLabel } from '../Common/SummaryLabel';
import SectionBlock, { ISummaryNotification, NOTIFICATION_TYPE } from '../Common/SectionBlock';

const RoomSetupSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'roomSetup';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();

  const { eventSettings, snapshots } = useEventSettings();
  const { equipment, equipmentSpecifics, notes } = eventSettings?.roomSetup ?? {};

  const { roomSetup: sentToCustomerRoomSetup } =
    snapshots.find((snapshot) => snapshot.snapshotType === 'SENT_TO_CUSTOMER') ?? {};

  const { roomSetup: finishedByCustomerRoomSetup } =
    snapshots.find((snapshot) => snapshot.snapshotType === 'FINISHED_BY_CUSTOMER') ?? {};

  const getEquipmentNotification = (): ISummaryNotification[] | undefined => {
    if (
      !sentToCustomerRoomSetup?.equipmentSpecifics &&
      (typeof sentToCustomerRoomSetup?.equipment === 'undefined' || sentToCustomerRoomSetup.equipment.length === 0)
    ) {
      return [
        {
          id: 'equipment-missing',
          text: t('eventDetails.settings.tabs.summary.notifications.roomSetup.equipment.missing'),
          notificationType: NOTIFICATION_TYPE[0],
        },
      ];
    }

    return undefined;
  };

  const getNotesNotification = (): ISummaryNotification[] | undefined => {
    if (typeof finishedByCustomerRoomSetup?.notes !== 'undefined' && finishedByCustomerRoomSetup.notes.length > 0) {
      return [
        {
          id: 'notes-filled',
          text: t('eventDetails.settings.tabs.summary.notifications.roomSetup.notes.filled'),
          notificationType: NOTIFICATION_TYPE[1],
        },
      ];
    }

    return undefined;
  };

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.${sectionId}.title`)} hidden={!isVisible}>
      <SectionBlock data-testid="room-setup-equipment" notification={getEquipmentNotification()}>
        <SummaryLabel label={t('eventDetails.settings.tabs.roomSetup.neededRoomSetupLabel')} />
        {(!equipment || equipment.length === 0) && <ReadonlyField text={t('noData')} variant="EMPTY" />}
        {equipment &&
          equipment.length > 0 &&
          equipment.map((equipmentItem) => {
            const id = equipmentItem.id;
            const params = equipmentItem.params ? equipmentItem.params : {};
            return (
              <ReadonlyField
                key={equipmentItem.id}
                text={t(`eventDetails.settings.tabs.roomSetup.equipment.readOnlyView.${id}_READONLY`, {
                  amount: params.count,
                  amount_white: params.card_count_white,
                  amount_colored: params.card_count_colored,
                })}
                variant="TRUE"
              />
            );
          })}
      </SectionBlock>

      <SectionBlock data-testid="room-setup-equipment-secifics">
        <SummaryLabel
          label={t('eventDetails.settings.tabs.roomSetup.equipmentSpecificsLabel')}
          copyText={
            equipmentSpecifics
              ? `${t('eventDetails.settings.tabs.roomSetup.equipmentSpecificsHint')}: ${equipmentSpecifics}`
              : undefined
          }
        />
        {equipmentSpecifics ? (
          <ReadonlyField text={equipmentSpecifics} variant="TEXT" />
        ) : (
          <ReadonlyField text={t('noData')} variant="EMPTY" />
        )}
      </SectionBlock>

      <SectionBlock data-testid="room-setup-notes" notification={getNotesNotification()}>
        <SummaryLabel
          label={t('eventDetails.settings.tabs.roomSetup.notesLabel')}
          copyText={notes ? `${t('eventDetails.settings.tabs.roomSetup.notesHint')}: ${notes}` : undefined}
        />
        {notes ? <ReadonlyField text={notes} variant="TEXT" /> : <ReadonlyField text={t('noData')} variant="EMPTY" />}
      </SectionBlock>
    </SectionSummary>
  );
};

export default RoomSetupSummary;
