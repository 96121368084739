import { FunctionComponent } from 'react';
import SectionSummary from '../Common/SectionSummary';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useTranslation } from 'react-i18next';
import { IEventSettingsData } from '@shared/interfaces';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import { SummaryLabel } from '../Common/SummaryLabel';
import SectionBlock, { ISummaryNotification, NOTIFICATION_TYPE } from '../Common/SectionBlock';

const TrainerAccommodationSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'trainerHotel';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();
  const { eventSettings, snapshots } = useEventSettings();

  const { bookingBy, accomodationNote, hotelName, hotelSuggestion } = eventSettings?.trainerHotel ?? {};

  const getBookedByTrans = (bookingBy: 'akademie' | 'customer' | 'notnecessary' | undefined) => {
    switch (bookingBy) {
      case 'akademie':
        return <ReadonlyField variant="TEXT" text={t('eventDetails.settings.tabs.trainerAccommodation.orgAkademie')} />;
      case 'customer':
        return (
          <ReadonlyField variant="TEXT" text={t('eventDetails.settings.tabs.trainerAccommodation.contactPerson')} />
        );
      case 'notnecessary':
        return (
          <ReadonlyField variant="TEXT" text={t('eventDetails.settings.tabs.trainerAccommodation.notnecessary')} />
        );
      default:
        return <ReadonlyField variant="EMPTY" text={t('noData')} />;
    }
  };

  const getBookedByValue = (bookingBy: 'akademie' | 'customer' | 'notnecessary' | undefined) => {
    switch (bookingBy) {
      case 'akademie':
        return hotelSuggestion === '' ? undefined : hotelSuggestion;
      case 'customer':
        return hotelName === '' ? undefined : hotelName;
      default:
        return undefined;
    }
  };

  const bookedByValueLabel = bookingBy === 'customer' ? 'hotelName' : 'hotelSuggestion';

  const sentToCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'SENT_TO_CUSTOMER');
  const finishedByCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'FINISHED_BY_CUSTOMER');

  const { trainerHotel: sentToCustomerTrainerHotel } = sentToCustomerSnapshot ?? {};
  const { trainerHotel: finishedByCustomerTrainerHotel } = finishedByCustomerSnapshot ?? {};

  const getBookingByNotification = (): ISummaryNotification[] | undefined => {
    if (finishedByCustomerTrainerHotel?.bookingBy === 'akademie') {
      return [
        {
          id: 'bookingBy-academy',
          text: t('eventDetails.settings.tabs.summary.notifications.trainerHotel.bookingBy.academy'),
          notificationType: NOTIFICATION_TYPE[0],
        },
      ];
    }

    return undefined;
  };

  const getHotelSuggestion = (): ISummaryNotification[] | undefined => {
    if (
      (typeof sentToCustomerTrainerHotel?.hotelSuggestion === 'undefined' ||
        sentToCustomerTrainerHotel.hotelSuggestion.length === 0) &&
      typeof finishedByCustomerTrainerHotel?.hotelSuggestion !== 'undefined' &&
      finishedByCustomerTrainerHotel?.hotelSuggestion.length > 0
    ) {
      return [
        {
          id: 'hotelSuggestion-filled',
          text: t('eventDetails.settings.tabs.summary.notifications.trainerHotel.hotelSuggestion.filled'),
          notificationType: NOTIFICATION_TYPE[1],
        },
      ];
    }

    if (sentToCustomerTrainerHotel?.hotelSuggestion !== finishedByCustomerTrainerHotel?.hotelSuggestion) {
      return [
        {
          id: 'hotelSuggestion-changed',
          text: t('eventDetails.settings.tabs.summary.notifications.trainerHotel.hotelSuggestion.changed'),
          notificationType: NOTIFICATION_TYPE[1],
        },
      ];
    }

    return undefined;
  };

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.trainerAccommodation.title`)} hidden={!isVisible}>
      <SectionBlock data-testid="trainer-hotel-booking-by" notification={getBookingByNotification()}>
        <SummaryLabel label={t('eventDetails.settings.tabs.trainerAccommodation.bookingBy')} />
        {getBookedByTrans(bookingBy)}
      </SectionBlock>

      <SectionBlock>
        <SummaryLabel
          label={t('eventDetails.settings.tabs.trainerAccommodation.accomodationNote')}
          copyText={accomodationNote ? accomodationNote : undefined}
        />
        <ReadonlyField variant={accomodationNote ? 'TEXT' : 'EMPTY'} text={accomodationNote ?? t('noData')} />
      </SectionBlock>

      {['akademie', 'customer'].includes(bookingBy || '') && (
        <SectionBlock data-testid="trainer-hotel-suggestion" notification={getHotelSuggestion()}>
          <SummaryLabel
            label={t(`eventDetails.settings.tabs.trainerAccommodation.${bookedByValueLabel}`)}
            copyText={
              getBookedByValue(bookingBy)
                ? t(`eventDetails.settings.tabs.trainerAccommodation.${bookedByValueLabel}`) +
                  ': ' +
                  getBookedByValue(bookingBy)
                : undefined
            }
          />
          <ReadonlyField
            variant={getBookedByValue(bookingBy) ? 'TEXT' : 'EMPTY'}
            text={getBookedByValue(bookingBy) ?? t('noData')}
          />
        </SectionBlock>
      )}
    </SectionSummary>
  );
};

export default TrainerAccommodationSummary;
