import { FunctionComponent } from 'react';
import { DefaultLabel } from '../Common/DefaultLabel';
import { useTranslation } from 'react-i18next';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { ReadonlyField } from '../Common/ReadonlyField';
import SectionBlock from '../Common/SectionBlock';

const TrainerAccommodationReadonly: FunctionComponent = () => {
  const { t } = useTranslation();
  const { eventSettings } = useEventSettings();

  if (!eventSettings) {
    return <></>;
  }

  const { bookingBy, accomodationNote, hotelName, hotelSuggestion } = eventSettings.trainerHotel ?? {};

  const getBookedByTrans = (bookingBy: 'akademie' | 'customer' | 'notnecessary' | undefined) => {
    switch (bookingBy) {
      case 'akademie':
        return t('eventDetails.settings.tabs.trainerAccommodation.orgAkademie');
      case 'customer':
        return t('eventDetails.settings.tabs.trainerAccommodation.contactPerson');
      case 'notnecessary':
        return t('eventDetails.settings.tabs.trainerAccommodation.notnecessary');
      default:
        return t('noData');
    }
  };

  const getBookedByValue = (bookingBy: 'akademie' | 'customer' | 'notnecessary' | undefined) => {
    switch (bookingBy) {
      case 'akademie':
        return hotelSuggestion === '' ? undefined : hotelSuggestion;
      case 'customer':
        return hotelName === '' ? undefined : hotelName;
      default:
        return undefined;
    }
  };

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.trainerAccommodation.bookingBy')}
          alertText={t(`eventDetails.settings.tabs.trainerAccommodation.bookingByHintReadOnly`)}
        />
        {getBookedByTrans(bookingBy)}
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.trainerAccommodation.accomodationNote')}
          alertText={t(`eventDetails.settings.tabs.trainerAccommodation.accomodationNoteHint`)}
        />
        <ReadonlyField variant={accomodationNote ? 'TEXT' : 'EMPTY'} text={accomodationNote ?? t('noData')} />
      </SectionBlock>

      {['akademie', 'customer'].includes(bookingBy || '') && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t(
              `eventDetails.settings.tabs.trainerAccommodation.${
                bookingBy === 'customer' ? 'hotelName' : 'hotelSuggestion'
              }`
            )}
            alertText={t('eventDetails.settings.tabs.trainerAccommodation.hotelHintReadOnly')}
          />
          <ReadonlyField
            variant={getBookedByValue(bookingBy) ? 'TEXT' : 'EMPTY'}
            text={getBookedByValue(bookingBy) ?? t('noData')}
          />
        </SectionBlock>
      )}
    </>
  );
};

export default TrainerAccommodationReadonly;
