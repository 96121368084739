import { IAddress, IContactDataSettings } from '@shared/interfaces';
import { FunctionComponent } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import ContactPersons from '../Common/ContactPersons';
import AddressForm, { INITIAL_ADDRESS, checkAdressCompleted } from '../Common/AddressForm';
import { DefaultLabel } from '../Common/DefaultLabel';
import SectionBlock from '../Common/SectionBlock';

export const contactDataCompleted = (contactData: IContactDataSettings | undefined) => {
  return contactData && checkAdressCompleted(contactData.customerAddress) && contactData.contactPersonsCustomer?.[0];
};

const ContactDataForm: FunctionComponent = () => {
  const { event: _event, eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();

  const { t } = useTranslation();

  if (!eventSettings) return <></>;
  const { orderNumber, extraBillingInformation } = eventSettings.contactData ?? {};

  return (
    <>
      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.contactData.emailAddressHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.contactData.addContactPersonLabel')}
          alertSeverity="info"
        />
        <ContactPersons />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.contactData.billingAddressHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.contactData.billingAddressLabel')}
          alertSeverity="info"
        />
        <AddressForm
          isCustomAddress={false}
          address={eventSettings.contactData?.customerAddress ?? INITIAL_ADDRESS}
          onChange={(address: Partial<IAddress> | undefined) =>
            eventSettingsDispatch({ type: 'update-contact-address', payload: address })
          }
        />
      </SectionBlock>
      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.contactData.orderNumberHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.contactData.orderNumberLabel')}
          alertSeverity="info"
        />
        <TextField
          label={t('eventDetails.settings.tabs.contactData.orderNumber')}
          value={orderNumber}
          inputProps={{ maxLength: 35 }}
          placeholder={t('eventDetails.settings.tabs.contactData.orderNumberPlaceholder')}
          onChange={(e) =>
            eventSettingsDispatch({
              type: 'update',
              payload: { contactData: { orderNumber: e.target.value } },
            })
          }
          margin="normal"
          fullWidth
        />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertText={t('eventDetails.settings.tabs.contactData.extraBillingInformationHint')}
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.contactData.extraBillingInformationLabel')}
          alertSeverity="info"
        />
        <TextField
          multiline
          value={extraBillingInformation}
          label={t('eventDetails.settings.tabs.contactData.extraBillingInformation')}
          onChange={(e) =>
            eventSettingsDispatch({
              type: 'update',
              payload: { contactData: { extraBillingInformation: e.target.value } },
            })
          }
          margin="normal"
          fullWidth
          minRows={2}
        />
      </SectionBlock>
    </>
  );
};

export default ContactDataForm;
