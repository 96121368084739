import { FunctionComponent } from 'react';
import SectionSummary from '../Common/SectionSummary';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useTranslation } from 'react-i18next';
import { IEventSettingsData } from '@shared/interfaces';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import EventTime from './EventTime';
import SectionBlock, { ISummaryNotification, NOTIFICATION_TYPE } from '../Common/SectionBlock';
import { SummaryLabel } from '../Common/SummaryLabel';
import { Typography } from '@mui/material';
import { ReadonlyField } from '../Common/ReadonlyField';
import deepEqual from 'deep-equal';

const LocationOnlineSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'location';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();
  const { event, eventSettings, snapshots } = useEventSettings();

  const sentToCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'SENT_TO_CUSTOMER');
  const finishedByCustomerSnapshot = snapshots.find((snapshot) => snapshot.snapshotType === 'FINISHED_BY_CUSTOMER');

  const { location: sentToCustomerLocation } = sentToCustomerSnapshot ?? {};
  const { location: finishedByCustomerLocation } = finishedByCustomerSnapshot ?? {};

  if (!event || !eventSettings) {
    return <></>;
  }

  const getEmailSendNotifications = (): ISummaryNotification[] | undefined => {
    const recipientEmailAddressesNotification = getRecipientEmailAddressesNotification();
    const sendToParticipantsNotification = getSendToParticipantsNotification();

    if (recipientEmailAddressesNotification === undefined && sendToParticipantsNotification === undefined) {
      return undefined;
    }

    const notifications = [];
    if (recipientEmailAddressesNotification) {
      notifications.push(recipientEmailAddressesNotification);
    }
    if (sendToParticipantsNotification) {
      notifications.push(sendToParticipantsNotification);
    }
    return notifications;
  };

  const getRecipientEmailAddressesNotification = (): ISummaryNotification | undefined => {
    if (
      !deepEqual(sentToCustomerLocation?.recipientEmailAddresses, finishedByCustomerLocation?.recipientEmailAddresses)
    ) {
      return {
        id: 'recipientEmailAddresses',
        text: t('eventDetails.settings.tabs.summary.notifications.location.recipientEmailAddresses.changed'),
        notificationType: NOTIFICATION_TYPE[1],
      };
    }

    return undefined;
  };

  const getSendToParticipantsNotification = (): ISummaryNotification | undefined => {
    if (finishedByCustomerLocation?.sendToParticipants === true) {
      return {
        id: 'notificationSendToParticipants',
        text: t('eventDetails.settings.tabs.summary.location.notificationSendToParticipants'),
        notificationType: NOTIFICATION_TYPE[0],
      };
    }

    return undefined;
  };

  const getStartTimeNotification = (): ISummaryNotification[] | undefined => {
    if (sentToCustomerLocation?.startTime !== finishedByCustomerLocation?.startTime) {
      return [
        {
          id: 'startTime-filled',
          text: t('eventDetails.settings.tabs.summary.notifications.location.startTime.filled'),
          notificationType: NOTIFICATION_TYPE[1],
        },
      ];
    }

    return undefined;
  };

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.${sectionId}.title.online`)} hidden={!isVisible}>
      {event.ablauf && (
        <SectionBlock>
          <>
            <SummaryLabel label={t('eventDetails.settings.tabs.location.time')} />
            <EventTime ablauf={event.ablauf} />
          </>
        </SectionBlock>
      )}
      <SectionBlock data-testid="location-start-time" notification={getStartTimeNotification()}>
        <SummaryLabel
          label={t('eventDetails.settings.tabs.location.differentTimeLabel')}
          copyText={eventSettings.location?.startTime}
        />
        <ReadonlyField text={eventSettings.location?.startTime || ''} variant="TEXT" />
      </SectionBlock>
      <SectionBlock>
        <SummaryLabel label={t('eventDetails.settings.tabs.location.eventTool')} />
        <Typography>
          {t('eventDetails.settings.tabs.location.tool', { tool: event.location.name })}
          <br />
          {t('eventDetails.settings.tabs.location.responsibility', {
            responsibility: t(
              `eventDetails.settings.tabs.location.${event.location.isClientTool ? 'clientTool' : 'akaTool'}`
            ),
          })}
        </Typography>
      </SectionBlock>
      <SectionBlock data-testid="location-send-to-participants" notification={getEmailSendNotifications()}>
        <SummaryLabel label={t('eventDetails.settings.tabs.location.email.emailRecipientsLabel')} />

        <ReadonlyField
          variant="TEXT"
          error={!eventSettings.location?.recipientEmailAddresses?.[0]}
          text={eventSettings.location?.recipientEmailAddresses?.[0] ?? t('missingData')}
        />
        {eventSettings.location?.sendToParticipants === true && (
          <ReadonlyField text={t(`eventDetails.settings.tabs.location.email.sendToParticipants`)} variant="TRUE" />
        )}
      </SectionBlock>
    </SectionSummary>
  );
};

export default LocationOnlineSummary;
