import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import AddressMultiForm, { ISelectableAddress } from '../Common/AddressMultiForm';
import { useEventSettings, useEventSettingsDispatch } from '../../../Context/EventSettingsContext';
import EventTime from './EventTime';
import { DefaultLabel } from '../Common/DefaultLabel';
import SectionBlock from '../Common/SectionBlock';
import { IAblauf, IAddress } from '@shared/interfaces';
import { ReadonlyField } from '../Common/ReadonlyField';

interface ILocationOnsiteSettings {
  ablauf: IAblauf[];
}

const LocationOnsiteForm: FunctionComponent<ILocationOnsiteSettings> = ({ ablauf }) => {
  const { eventSettings } = useEventSettings();
  const eventSettingsDispatch = useEventSettingsDispatch();

  const { t } = useTranslation();

  if (!eventSettings) return <></>;

  const { eventLocation, locationNotes, startTime } = eventSettings.location ?? {};

  const addressHintTranslationKeySuffix = eventLocation ? 'customAddress' : 'debitorAddress';

  const selectableAddresses: ISelectableAddress[] = eventSettings.contactData?.customerAddress
    ? [
        {
          address: eventSettings.contactData?.customerAddress,
          key: 'contactData',
          label: t('eventDetails.settings.tabs.contactData.customerAddress'),
        },
      ]
    : [];

  return (
    <>
      {ablauf && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t('eventDetails.settings.tabs.location.time')}
            alertText={t(`eventDetails.settings.tabs.location.timeHintReadOnly`)}
          />
          <EventTime ablauf={ablauf} />
        </SectionBlock>
      )}
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.location.differentTimeLabel')}
          alertText={t(`eventDetails.settings.tabs.location.differentTimeHint`)}
        />
        <ReadonlyField text={startTime ?? ''} variant="TEXT" />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.location.eventLocation')}
          alertText={t(`eventDetails.settings.tabs.location.locationHint.${addressHintTranslationKeySuffix}`)}
        />
        <AddressMultiForm
          dialogTitle={t('eventDetails.settings.tabs.location.eventLocation')}
          customAddress={eventSettings.location?.eventLocation}
          onChangeCustomAddress={(address: Partial<IAddress> | undefined) =>
            eventSettingsDispatch({
              type: 'update',
              payload: { location: { eventLocation: address, eventLocationRef: 'location' } },
            })
          }
          selectableAddresses={selectableAddresses}
        />
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.location.locationNotes')}
          alertText={t(`eventDetails.settings.tabs.location.locationNotesHint`)}
        />
        <ReadonlyField text={locationNotes ?? ''} variant="TEXT" />
      </SectionBlock>
    </>
  );
};

export default LocationOnsiteForm;
