import { Dispatch, FunctionComponent, SetStateAction, SyntheticEvent, useContext, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { IEventListItemResponse, IEventSettingsData, IEventSettingsResponse } from '@shared/interfaces';
import { useTranslation } from 'react-i18next';
import Summary from './EventSettings/Summary';
import { useEventSettings } from '../Context/EventSettingsContext';
import JWTContext from '../JWTContext';
import { ALLOWED_GROUPS } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';
import { STAGE } from '../../config';
import AlertSave from '../AlertSnackbar/AlertSave';
import ContactData from './EventSettings/ContactData/ContactData';
import TrainerAccommodation from './EventSettings/TrainerAccommodation/TrainerAccommodation';
import Location from './EventSettings/Location/Location';
import RoomSetup from './EventSettings/RoomSetup/RoomSetup';
import SeminarDocuments from './EventSettings/SeminarDocuments/SeminarDocuments';
import ConfirmationOfParticipation from './EventSettings/ConfirmationOfParticipation/ConfirmationOfParticipation';
import Feedback from './EventSettings/Feedback/Feedback';
import Participants from './EventSettings/Participants/Participants';
import { checkAdressCompleted } from './EventSettings/Common/AddressForm';
import { useLocation } from 'react-router-dom';
import { ColorPalette } from '@hmg-aka-lu/elevate-theme';
import { styled } from '@mui/system';

interface IEventSettingsProps {
  event: IEventListItemResponse;
  eventId: string;
  eventSettings: IEventSettingsResponse;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  tabId: string;
  value: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, tabId, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== tabId}
      id={`full-width-tabpanel-${tabId}`}
      aria-labelledby={`full-width-tab-${tabId}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === tabId && <Box>{children}</Box>}
    </div>
  );
};

export interface ICommonEventSettingsProps<T> {
  eventId: string;
  data: T | undefined;
  setData: Dispatch<SetStateAction<T | undefined>>;
}

interface ITab {
  id: keyof IEventSettingsData;
  display: () => boolean;
  label: string;
  component: JSX.Element;
}

const EventSettings: FunctionComponent<IEventSettingsProps> = ({ event }) => {
  const { hasRequiredGroup, authenticatedContext } = useContext(JWTContext);
  const [alertSave, setAlertSave] = useState(false);
  const [nextTab, setNextTab] = useState('');
  const { hash } = useLocation();

  // Used to grant access to event configuration for specific users in the prod environment
  // In test environment all users have access to the configuration
  // This logic can probably be removed once the event configuration / kundenfragebogen is released
  const USER_HAS_PREVIEW_ACCESS =
    STAGE === 'prod'
      ? hasRequiredGroup(
          [ALLOWED_GROUPS.SUPER_USER, ALLOWED_GROUPS.ORGANIZATIONS_COCKPIT_PREVIEW],
          authenticatedContext.groups
        )
      : true;

  const { eventSettings, eventSettingsOriginal, dirty } = useEventSettings();
  const [activeTab, setActiveTab] = useState(() => {
    if (hash.substring(1) === 'summary') {
      return 'summary';
    }

    if (USER_HAS_PREVIEW_ACCESS) {
      return eventSettings?.status === 'FINISHED_BY_CUSTOMER' ? 'summary' : 'contactData';
    } else {
      return 'feedback';
    }
  });

  const { t } = useTranslation();

  if (!eventSettings) return <></>;

  const INITIAL_CONTACT_DATA_SET =
    // STAGE === 'prod' ||
    !!eventSettingsOriginal?.contactData?.customerAddress &&
    checkAdressCompleted(eventSettingsOriginal?.contactData?.customerAddress) &&
    !!eventSettingsOriginal?.contactData?.contactPersonsCustomer?.[0];

  const StyledTab = styled(Tab)({
    textTransform: 'none',

    alignSelf: 'flex-start',
    width: '100%',
    mb: 1,

    justifyContent: 'center',
    alignItems: 'start',
    textAlign: 'left',
    '&.MuiTab-root': {
      backgroundColor: ColorPalette.surface.elevated,
      borderRadius: 8,
      padding: '12px 16px',
      margin: '0 0 10px',
      alignSelf: 'flex-start',
      minHeight: '3rem',
      marginLeft: 0,
      marginRight: 0,
    },
    '&.Mui-selected': {
      backgroundColor: ColorPalette.surface.brandLight,
      borderColor: ColorPalette.surface.brand,
      borderStyle: 'solid',
      borderWidth: '2px',
      color: ColorPalette.text.brand,
    },
  }) as typeof Tab;
  const tabStyle = {};

  const tabs: ITab[] = [
    {
      id: 'contactData',
      display: () => USER_HAS_PREVIEW_ACCESS,
      label: t('eventDetails.settings.tabs.contactData.label'),
      component: <ContactData readonlyMode={INITIAL_CONTACT_DATA_SET} />,
    },
    {
      id: 'location',
      display: () => USER_HAS_PREVIEW_ACCESS && INITIAL_CONTACT_DATA_SET,
      label: t(`eventDetails.settings.tabs.location.${event.isOnline ? 'online' : 'onsite'}`),
      component: <Location />,
    },
    {
      id: 'trainerHotel',
      display: () => USER_HAS_PREVIEW_ACCESS && !event.isOnline && INITIAL_CONTACT_DATA_SET,
      label: t('eventDetails.settings.tabs.trainerAccommodation.label'),
      component: <TrainerAccommodation />,
    },
    {
      id: 'roomSetup',
      display: () => USER_HAS_PREVIEW_ACCESS && !event.isOnline && INITIAL_CONTACT_DATA_SET,
      label: t('eventDetails.settings.tabs.roomSetup.label'),
      component: <RoomSetup />,
    },
    {
      id: 'seminarDocuments',
      display: () => USER_HAS_PREVIEW_ACCESS && INITIAL_CONTACT_DATA_SET,
      label: t('eventDetails.settings.tabs.seminarDocuments.label'),
      component: <SeminarDocuments />,
    },
    {
      id: 'confirmationOfParticipation',
      display: () => USER_HAS_PREVIEW_ACCESS && INITIAL_CONTACT_DATA_SET,
      label: t('eventDetails.settings.tabs.confirmationOfParticipation.label'),
      component: <ConfirmationOfParticipation />,
    },
    {
      id: 'feedback',
      display: () => STAGE === 'prod' || INITIAL_CONTACT_DATA_SET,
      label: t('eventDetails.settings.tabs.feedback.label'),
      component: <Feedback />,
    },
    {
      id: 'participants',
      display: () => USER_HAS_PREVIEW_ACCESS && INITIAL_CONTACT_DATA_SET,
      label: t('eventDetails.settings.tabs.participants.label'),
      component: <Participants />,
    },
  ];

  const handleChange = (_inputEvent: SyntheticEvent<Element, Event>, value: string) => {
    setNextTab(value);
    Object.keys(dirty).length > 0 ? setAlertSave(true) : setActiveTab(value);
  };

  const callBackSaveAlert = () => {
    setActiveTab(nextTab);
  };

  return (
    <Box flexGrow={1} display={'flex'} mb={4}>
      <Tabs
        orientation="vertical"
        variant="fullWidth"
        value={activeTab}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          minWidth: 320,
          mr: 3,
          height: 515,
          overflow: 'auto',
          position: 'sticky',
          top: '10px',
        }}
      >
        {tabs.map((tab) => (
          <StyledTab
            disabled={!tab.display()}
            value={tab.id}
            key={`tab-${tab.id}`}
            sx={{
              ...tabStyle,
              color: '#00ff00',
            }}
            label={tab.label}
          />
        ))}
        <StyledTab
          disabled={!INITIAL_CONTACT_DATA_SET || !USER_HAS_PREVIEW_ACCESS}
          value="summary"
          key="tab-summary"
          sx={tabStyle}
          label={
            ['SENT_TO_CUSTOMER', 'FINISHED_BY_CUSTOMER'].includes(eventSettings.status)
              ? t('eventDetails.settings.tabs.summary.summaryLabel')
              : t('eventDetails.settings.tabs.summary.summaryAndSendLabel')
          }
        />
      </Tabs>
      {tabs.map((tab) => (
        <TabPanel value={activeTab} tabId={tab.id} key={`tab-panel-${tab.id}`}>
          {eventSettings && tab.component}
        </TabPanel>
      ))}
      {USER_HAS_PREVIEW_ACCESS && INITIAL_CONTACT_DATA_SET && (
        <TabPanel value={activeTab} tabId="summary">
          <Summary />
        </TabPanel>
      )}
      {alertSave && (
        <AlertSave
          openAlert={setAlertSave}
          callBackFn={callBackSaveAlert}
          setVisibility={setAlertSave}
          message={t('eventDetails.saveAlertBox.notSavedData')}
        />
      )}
    </Box>
  );
};

export default EventSettings;
