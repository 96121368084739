import { Box, Breadcrumbs, Grid, Link, Paper, Tooltip, Typography } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { NavLink } from 'react-router-dom';
import { MICROFRONTEND_ID } from '../../config';
import { ColorPalette } from '@hmg-aka-lu/elevate-theme';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import JWTContext from '../JWTContext';
import { IEventListItemResponse, IInternalContactPersons } from '@shared/interfaces/src/event';
import { useInternalContacts } from '../../queries/internal-contacts';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useEventSettings } from '../Context/EventSettingsContext';
import { useActivityLogs } from '../../queries/activity-logs';

export interface IBreadcrumbItem {
  label: string;
  link?: string;
}

interface IEventHeaderProps {
  event: IEventListItemResponse;
  breadcrumbItems?: IBreadcrumbItem[];
}

export const EventHeader: FunctionComponent<IEventHeaderProps> = ({ breadcrumbItems = [], event }) => {
  const { getJWT } = useContext(JWTContext);
  const eventContactPerson = event?.internalContactPersons?.find((person) => person.type === 'OrgaThemen');
  const { eventSettings } = useEventSettings();
  const { data: activityLogs } = useActivityLogs(event.id, getJWT);

  const { data: internalContactPerson, isFetching: isFetchingInternalContact } = useInternalContacts(
    eventContactPerson?.email ?? '',
    getJWT
  );

  const { t } = useTranslation();

  const getPhoneErrorText = (internalContactPerson: IInternalContactPersons | undefined) => {
    if (isFetchingInternalContact || (internalContactPerson && internalContactPerson.tel != '')) {
      return <></>;
    }
    return (
      <Tooltip title={t('events.missingInternalContactTel')}>
        <ErrorOutlinedIcon sx={{ verticalAlign: 'bottom' }} color="error" />
      </Tooltip>
    );
  };

  const showStatusHeader =
    eventSettings?.status === 'SENT_TO_CUSTOMER' || eventSettings?.status === 'FINISHED_BY_CUSTOMER';
  const completedByCustomerDate = activityLogs?.find(
    (activityLog) => activityLog.eventType === 'aka.ban.los.organizations.kundenfragebogen.completed-by-customer'
  )?.createDate;

  return (
    <>
      <Breadcrumbs sx={{ mb: 1 }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link component={NavLink} underline="hover" key="1" to={`/${MICROFRONTEND_ID}/events`}>
          {t('events.header')}
        </Link>
        {breadcrumbItems.map((breadcrumbItem) =>
          breadcrumbItem.link ? (
            <Link key={breadcrumbItem.label} component={NavLink} underline="hover" to={breadcrumbItem.link}>
              {breadcrumbItem.label}
            </Link>
          ) : (
            <Typography key={breadcrumbItem.label} color="text.primary">
              {breadcrumbItem.label}
            </Typography>
          )
        )}
      </Breadcrumbs>
      <Box component={Paper} bgcolor={ColorPalette.surface.elevated} p={2} elevation={0} mb={2}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="headingSm" color={ColorPalette.text.brand} mb={2}>
              {event.title}
            </Typography>
            <Typography>
              <strong>
                {event.id} -
                {event.isOnline ? t('eventDetails.header.format.online') : t('eventDetails.header.format.onsite')}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography paragraph mb={0.5}>
              {t('eventDetails.header.date')}{' '}
              <strong>
                {format(new Date(event.startDate), 'dd.MM.yyyy')} - {format(new Date(event.endDate), 'dd.MM.yyyy')}
              </strong>
            </Typography>
            <Typography paragraph mb={0.5}>
              {t('eventDetails.header.trainer')}{' '}
              <strong>{event.trainers?.map((trainer) => trainer.name).join(', ')}</strong>
            </Typography>
            <Typography paragraph mb={0.5}>
              {t('eventDetails.header.internalContact')}{' '}
              {eventContactPerson && (
                <strong>
                  {eventContactPerson.name} {getPhoneErrorText(internalContactPerson)}
                </strong>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {showStatusHeader && (
        <Box component={Paper} bgcolor={ColorPalette.surface.brandLight} p={2} elevation={0} mb={2}>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center' }}>
                {eventSettings.status === 'SENT_TO_CUSTOMER' && t('eventDetails.header.notEditable')}
                {eventSettings.status === 'FINISHED_BY_CUSTOMER' && (
                  <>
                    {t('eventDetails.header.returnedFromCustomer')}{' '}
                    {completedByCustomerDate && (
                      <>
                        {t('intlDateTime', {
                          val: new Date(completedByCustomerDate),
                          formatParams: {
                            val: {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          },
                        })}{' '}
                        {t('intlDateTime', {
                          val: new Date(completedByCustomerDate),
                          formatParams: {
                            val: {
                              hour: '2-digit',
                              minute: '2-digit',
                            },
                          },
                        })}
                      </>
                    )}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
