import { Alert, AlertTitle, IconButton } from '@mui/material';
import { Dispatch, FunctionComponent, ReactNode, SetStateAction } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

interface IAlertItemProps {
  alertText: string | ReactNode;
  alertTitle?: string;
  alertSeverity: 'info' | 'error' | 'warning' | 'success';
  enableAlert: boolean;
  setEnableAlert: Dispatch<SetStateAction<boolean>>;
  enableCloseButton?: boolean;
}

export const AlertItem: FunctionComponent<IAlertItemProps> = ({
  alertText,
  alertTitle,
  alertSeverity,
  enableAlert = false,
  setEnableAlert,
  enableCloseButton = true,
}) => {
  return (
    <>
      {enableAlert && (
        <Alert
          action={
            enableCloseButton && (
              <IconButton
                aria-label="close"
                color="default"
                size="small"
                onClick={() => {
                  setEnableAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
          variant="outlined"
          color="info"
          severity={alertSeverity}
          iconMapping={{ info: <InfoOutlinedIcon color="warning" /> }}
          sx={{ mb: 2, mt: 1, color: 'black' }}
        >
          {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
          {alertText}
        </Alert>
      )}
    </>
  );
};
