import { FunctionComponent, useContext } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import JWTContext from '../components/JWTContext';
import { useEvent } from '../queries/events';
import { Trans, useTranslation } from 'react-i18next';
import EventSettings from '../components/Events/EventSettings';
import { useEventSettings } from '../queries/event-settings';
import { IEventListItemResponse, IEventSettingsResponse } from '@shared/interfaces';
import { EventSettingssProvider } from '../components/Context/EventSettingsContext';
import { ColorPalette } from '@hmg-aka-lu/elevate-theme';
import { EventHeader, IBreadcrumbItem } from '../components/Events/Header';
import { EventOverview } from '../components/Events/Overview';
import { MICROFRONTEND_ID, MSTEAM_SUPPORT_CHANNEL } from '../config';
import { useEventSettingsSnapshots } from '../queries/event-settings-snapshots';

interface IEventDetailsProps {
  view: 'configuration' | 'overview';
}

export const EventDetails: FunctionComponent<IEventDetailsProps> = ({ view = 'configuration' }) => {
  const { eventId } = useParams();

  if (!eventId) {
    throw new Error('Missing event id parameter');
  }

  const { getJWT } = useContext(JWTContext);
  const { data: event, isLoading } = useEvent(eventId, getJWT);
  const { data: eventSettings, isFetched } = useEventSettings(eventId, getJWT);
  const { data: eventSettingsSnapshots } = useEventSettingsSnapshots(eventId, getJWT);

  const { t } = useTranslation();

  // TODO: check how initialEventSettings could be replaced or removed
  const initialEventSettings: IEventSettingsResponse = {
    eventId: eventId,
    status: 'STARTED',
    confirmationOfParticipation: { enabled: true },
    seminarDocuments: { format: 'digital' },
    completenessCheck: {
      confirmationOfParticipation: false,
      contactData: false,
      roomSetup: false,
      seminarDocuments: false,
      trainerHotel: false,
      billing: false,
      feedback: false,
      location: false,
      participants: false,
    },
    hiddenSections: [],
  };

  if (!isFetched || isLoading) {
    return <LinearProgress />;
  }

  if (!event) {
    return (
      <p>
        <Trans
          i18nKey={'eventDetails.settings.tabs.feedback.eventNotFound'}
          components={{
            a: (
              <a
                aria-label={t('eventDetails.settings.tabs.feedback.msTeams')}
                target="_blank"
                href={MSTEAM_SUPPORT_CHANNEL}
              ></a>
            ),
            ol: <ol></ol>,
            li: <li></li>,
          }}
          values={{
            tool: t('eventDetails.settings.tabs.feedback.msTeams'),
          }}
        />
      </p>
    );
  }

  const getBreadcrumbItems = (): IBreadcrumbItem[] => {
    switch (view) {
      case 'configuration':
        return [
          {
            label: event.title.substring(0, 24) + '...',
            link: `/${MICROFRONTEND_ID}/events/${eventId}/overview`,
          },
          {
            label: t('event.breadcrumb.configuration'),
          },
        ];

      case 'overview':
        return [
          {
            label: event.title.substring(0, 24) + '...',
          },
        ];
    }
  };

  const getInternalContactPersons = (event: IEventListItemResponse) => {
    const contactPerson = event.internalContactPersons.find((person) => person.type === 'OrgaThemen');
    return contactPerson ? [contactPerson] : [];
  };

  return (
    eventSettingsSnapshots && (
      <EventSettingssProvider
        debitorId={event.debitorId}
        eventSettings={{ ...initialEventSettings, ...eventSettings }}
        event={{
          ...event,
          internalContactPersons: getInternalContactPersons(event),
        }}
        snapshots={eventSettingsSnapshots}
      >
        <Box m={-3} p={3} bgcolor={ColorPalette.surface.background} maxWidth={2000}>
          <EventHeader event={event} breadcrumbItems={getBreadcrumbItems()} />

          {view === 'configuration' && (
            <EventSettings
              event={event}
              eventId={eventId}
              eventSettings={{ ...initialEventSettings, ...eventSettings }}
            />
          )}

          {view === 'overview' && <EventOverview eventId={eventId} />}
        </Box>
      </EventSettingssProvider>
    )
  );
};
