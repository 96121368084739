import { FunctionComponent } from 'react';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { useTranslation } from 'react-i18next';
import EventTime from './EventTime';
import { DefaultLabel } from '../Common/DefaultLabel';
import AddressReadOnly from '../Common/AddressReadOnly';
import SectionBlock from '../Common/SectionBlock';
import { ReadonlyField } from '../Common/ReadonlyField';
import { IAblauf } from '@shared/interfaces';

interface ILocationOnsiteSettings {
  ablauf: IAblauf[];
}

const LocationOnsiteReadonly: FunctionComponent<ILocationOnsiteSettings> = ({ ablauf }) => {
  const { eventSettings } = useEventSettings();
  const { t } = useTranslation();

  if (!eventSettings) return <></>;

  const { startTime, locationNotes } = eventSettings.location ?? {};

  return (
    <>
      {ablauf && (
        <SectionBlock>
          <DefaultLabel
            alertTitle={t('generalHintTitle')}
            label={t('eventDetails.settings.tabs.location.time')}
            alertText={t(`eventDetails.settings.tabs.location.timeHintReadOnly`)}
          />
          <EventTime ablauf={ablauf} />
        </SectionBlock>
      )}

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.location.differentTimeLabel')}
          alertText={t(`eventDetails.settings.tabs.location.differentTimeHint`)}
        />
        <ReadonlyField text={startTime || t('noData')} variant={startTime ? 'TEXT' : 'EMPTY'} />
      </SectionBlock>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.location.eventLocation')}
          alertText={t(`eventDetails.settings.tabs.location.eventLocationHintReadOnly`)}
        />
        {eventSettings.location?.eventLocation ? (
          <AddressReadOnly address={eventSettings.location.eventLocation} />
        ) : (
          <ReadonlyField text={t('noData')} variant={'EMPTY'} />
        )}
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.location.locationNotes')}
          alertText={t(`eventDetails.settings.tabs.location.locationNotesHint`)}
        />
        <ReadonlyField text={locationNotes || t('noData')} variant={locationNotes ? 'TEXT' : 'EMPTY'} />
      </SectionBlock>
    </>
  );
};
export default LocationOnsiteReadonly;
