export const i18n_de = {
  intlNumber: '{{val, number}}',
  intlDateTime: '{{val, datetime}}',
  missingData: 'Fehlende Angabe',
  noData: 'Keine Angaben',
  noDataAvailable: 'Keine Daten vorhanden',
  address: {
    line1: 'Firmenname',
    line2: 'Ansprechpartner:in / Adresszusatz',
    street: 'Strasse, Hausnummer',
    postalCode: 'Postleitzahl',
    city: 'Stadt',
    country: 'Land',
    reset: 'Auf Rechnungsadresse zurücksetzen',
    addNewAddress: 'Neue Adresse eintragen',
    customAddress: 'Abweichende Adresse',
    editAddress: 'Adresse bearbeiten',
    deleteAddress: 'Adresse löschen',
    dialog: {
      label: 'Abweichende Adresse',
      useAddress: '{{address}} übernehmen',
      save: 'Übernehmen',
      cancel: 'Abbrechen',
    },
  },
  countries: {
    AT: 'Österreich',
    CH: 'Schweiz',
    DE: 'Deutschland',
    undefined: '',
  },
  events: {
    header: 'Veranstaltungen',
    missingInternalContactTel:
      'Die Telefonnummer ist nicht gepflegt. Bitte wende dich an den "Support Inhouse-Cockpit"-Kanal in MS-Teams.',
    search: 'Suchen',
    eventId: 'Veranstaltungs-ID',
    planningsStatus: {
      STARTED: 'Begonnen',
      SENT_TO_CUSTOMER: 'Beim Kunden',
      FINISHED_BY_CUSTOMER: 'Abgeschlossen',
    },
  },
  generalHintTitle: 'Hinweis',
  eventDetails: {
    cannotBeSendNoReceipient:
      'Der Kundenfragebogen kann nicht versendet werden, da noch kein Empfänger hinterlegt wurde.',
    enterReceipient: 'Empfänger:in eintragen',
    sentLinkPerMail: 'Die E-Mail mit Link zum Kundenfragebogen wurde erfolgreich versendet.',
    accessLinkMail:
      'Mit Klick auf "Senden" erhält </p>"{{accessLinkASP}}" </p>eine E-Mail mit einem Zugangslink zum Kundenfragebogen. </p>Möchtest du die E-Mail jetzt senden?',
    common: {
      emailRecipients: {
        addRecipient: 'Empfänger:in hinzufügen',
        recipientLabel: 'E-Mail-Adresse',
        recipient: 'E-Mail-Adresse',
        removeRecipient: 'Empfänger:in entfernen',
      },
    },
    createUser: 'Ansprechpartner:in erstellen:',
    save: 'Speichern',
    saveAlertBox: {
      cancel: 'Abbrechen',
      reset: 'Änderungen verwerfen',
      save: 'Änderungen speichern',
      notSavedData: 'Es gibt Änderungen, die noch nicht gespeichert wurden.',
    },
    header: {
      trainer: 'Trainer:in: ',
      vaId: 'Veranstaltungs-ID: ',
      date: 'Datum: ',
      internalContact: 'Interner Kontakt: ',
      notEditable:
        'Der Kundenfragebogen liegt aktuell beim Kunden. Bis der Kunde diesen zurückgesendet hat, kann die VA-Konfiguration nicht bearbeitet werden.',
      returnedFromCustomer: 'Der Kundenfragebogen wurde vom Kunden zurück gesendet am:',
      format: {
        text: 'Format: ',
        online: 'Online',
        onsite: 'Präsenz',
      },
    },
    settings: {
      alerts: {
        updateError: 'Beim Speichern der Veranstaltungskonfiguration ist ein Fehler aufgetreten',
        updateSuccess: 'Die Veranstaltungskonfiguration wurde gespeichert',
      },
      sectionSwitch: {
        label: 'Diesen Bereich im Kundenfragebogen einblenden',
        checked: 'Angezeigt im Kundenfragebogen',
        unchecked: 'Ausgeblendet im Kundenfragebogen',
        readonlyDialogTitle: 'Abschnitt im Kundenfragebogen ausgeblendet!',
        readonlyDialogBody:
          'Dieser Abschnitt wird im Kundenfragebogen nicht angezeigt. Klicke auf den Switch (Ein-/Ausblenden) um diese Einstellung zu ändern.',
        editableDialogBody:
          'Dieser Abschnitt wird im Kundenfragebogen nicht angezeigt. Klicke auf "Ändern" um diese Einstellung über den Switch zu ändern.',
      },
      tabs: {
        billing: {
          additionalInformation: 'Zusätzliche Angaben',
          address: 'Rechnungsstellung',
          addressHint: {
            customAddress:
              'Deine Anpassung gilt nur für die Rechnungsadresse. Die eingetragene Adresse im Abschnitt "Kontaktdaten Kunde" ändert sich nicht.',
            debitorAddress:
              'Die vorgeschlagene Adresse entspricht der eingetragene Adresse im Abschnitt "Kontaktdaten Kunde". Du kannst die Rechnungsadresse hier anpassen.',
          },
        },
        confirmationOfParticipation: {
          label: 'Teilnahmebestätigung',
          certificationLabel: 'Teilnahmebestätigungen',
          certificateHint:
            'Bitte wähle aus, ob digitale Teilnahmebestätigungen nach der Veranstaltung an die unten stehende E-Mail-Adresse verschickt werden sollen.',
          addContactPerson: 'Empfänger:in hinzufügen',
          emailAddress: 'E-Mail-Adresse',
          enabledLabel: 'Digitale Teilnahmebestätigungen gewünscht',
          certificationsCheckbox: 'Ja, für diese Veranstaltung werden digitale Teilnahmebestätigungen gewünscht',
          disabledLabel: 'Keine digitalen Teilnahmebestätigungen gewünscht',
          unknownForNow: 'Ob Teilnahmebestätigungen gewünscht sind, soll der Kunde entscheiden',
          format: 'Format',
          removeContactPerson: 'Entfernen',
          recipientAddressLabel: 'E-Mail-Adresse',
          recipientAddressHint: `An diese E-Mail-Adresse werden nach der Veranstaltung die Teilnahmebestätigungen versendet.<br/>
          Die vorgeschlagene E-Mail-Adresse entspricht der E-Mail-Adresse aus dem Abschnitt "Kontaktdaten Kunde". Du kannst auch eine andere E-Mail-Adresse eintragen.`,
        },
        contactData: {
          label: 'Kontaktdaten Kunde',
          emailAddress: 'E-Mail-Adresse',
          customerAddress: 'Kundenadresse',
          contactPersonLabel: 'Ansprechpartner:in',
          addContactPersonLabel: 'Ansprechpartner:in hinzufügen',
          billingAddressLabel: 'Rechnungsadresse',
          extraBillingInformationLabel: 'Zusätzliche Angaben zur Rechnungsstellung',
          extraBillingInformation: 'Zusätzliche Angaben',
          orderNumberShort: 'Bestellnummer',
          orderNumber: 'Bestellnummer / Auftragsnummer / Einkaufsnummer',
          orderNumberHint: `Bitte hinterlege hier die Bestellnummer oder Auftragsnummer des Kunden, falls diese bereits bekannt ist. Die eingetragene Nummer wird später auf der Rechnung erscheinen.`,
          orderNumberLabel: 'Bestellnummer, Auftragsnummer',
          orderNumberPlaceholder: 'Bestellnummer (max. 35-stelliger Code)',
          emailAddressHint:
            'Bitte hinterlege hier die E-Mail-Adresse des Ansprechpartners, der Ansprechpartnerin. Der Kundenfragebogen wird später an diese E-Mail-Adresse geschickt.',
          billingAddressHint:
            'Bitte hinterlege hier die Rechnungsadresse des Ansprechpartners, der Ansprechpartnerin. Diese Adresse wird im weiteren Verlauf der Veranstaltungskonfiguration benötigt.',
          extraBillingInformationHint: 'Bitte hinterlege hier relevante Informationen für die Rechnungsstellung.',
          contactPersonsCompany: 'Ansprechpartner:in (Firma)',
          contactPersonsInternal: 'Ansprechpartner:in (Haufe Akademie)',
          addUser: 'Ansprechpartner:in hinzufügen',
          createUser: 'Ansprechpartner:in erstellen',
          justOneAnsprechpartner: 'Es kann nur ein Ansprechpartner, eine Ansprechpartnerin hinterlegt werden.',
          searchUser: 'Ansprechpartner:in suchen',
          createUserLabel: 'Ansprechpartner:in erstellen',
          errorCreateUser: 'Ansprechpartner:in konnte nicht erstellt werden',
          firstName: 'Vorname',
          lastName: 'Nachname',
          deletedUser: 'Gelöschter Benutzer',
        },
        feedback: {
          hintLabel: 'Feedbacksystem',
          system: 'Feedbacksystem',
          feedbackHint:
            'Hier wird angezeigt, ob für die Veranstaltung das Feedbacksystem der Haufe Akademie verwendet werden soll.',
          label: 'Feedback',
          contactPerson: 'Empfänger:in',
          recipientLabel: 'E-Mail-Adresse',
          recipientHint: `An diese E-Mail-Adresse wird nach der Veranstaltung die Feedbackauswertung versendet.<br/>
            Die vorgeschlagene E-Mail-Adresse entspricht der E-Mail-Adresse aus dem Abschnitt "Kontaktdaten Kunde". Du kannst auch eine andere E-Mail-Adresse eintragen.`,
          assessmentCenter: 'Assessment Center',
          eventNotFound: `Diese Veranstaltung konnte nicht gefunden werden. Das kann folgende Gründe haben:
                          <ol>
                            <li>Zu dieser Veranstaltung fehlt der Ort.</li>
                            <li>Zu dieser Veranstaltung fehlt die Debitor Identifikationsnummer.</li>
                          </ol>
                          <p>Bitte überprüfe die Daten in SAP. Versuche es am nächsten Tag nochmal oder wende dich an den Support-Kanal <a>{{tool}}</a></p>`,
          surveyTypeLabel: 'Feedback-Fragebogen',
          msTeams: 'MS-Teams',
          surveyTypeHint: {
            IHC: 'Bitte wähle den gewünschten Feedback-Fragebogen aus.',
            ReadOnly: 'Dies ist der im Standard verwendete Fragebogen',
          },
          surveyTypeFormHint: {
            IHC: 'Bitte wähle den passenden Fragebogen aus.',
            ReadOnly: 'Hier wird angezeigt, welcher Feedbackbogen verwendet wird.',
          },
          feedbackSystemCheckbox: 'Ja, für diese Veranstaltung wird das Feedbacksystem der Haufe Akademie verwendet',
          haufeFeedbackSystem: 'Feedbacksystem der Haufe Akademie wird verwendet',
          otherFeedbackSystem: 'Feedbacksystem der Haufe Akademie wird NICHT verwendet',
          surveyType: {
            DEFAULT: {
              ONLINE: 'TN Digital',
              ONSITE: 'TN Präsenz',
            },
            ASSESSMENT_CENTER: 'TN IHC Assessment Center',
          },
        },
        location: {
          label: 'Veranstaltungsort',
          onsite: 'Zeit und Ort',
          online: 'Tool und Zeit',
          differentTimeLabel: 'Abweichende Uhrzeit',
          differentTimeLabelHint: 'Kann vom Kunden ausgefüllt werden.',
          differentTimePlaceholder: 'Andere Uhrzeit gewünscht? Hier Wunschuhrzeit eintragen',
          eventLocation: 'Veranstaltungsort',
          email: {
            emailRecipientsLabel: 'E-Mail-Versand',
            recipientEmailHintReadonly: `<p>An diese E-Mail-Adresse wird der Zugangslink zum Tool per E-Mail verschickt.</p><p>Die vorgeschlagene E-Mail-Adresse entspricht der E-Mail-Adresse aus dem Abschnitt "Kontaktdaten Kunde". Du kannst auch eine andere E-Mail-Adresse eintragen.</p><p>Du musst den Zugangslink zusätzlich direkt an jeden einzelnen Teilnehmenden verschicken? Diese Option kannst du über "Ändern" auswählen. (Hinweis: Der Versand ist ein manueller Prozess.)</p>`,
            recipientEmailHintWrite: `<p>An diese E-Mail-Adresse wird der Zugangslink zum Tool per E-Mail verschickt.</p><p>Die vorgeschlagene E-Mail-Adresse entspricht der E-Mail-Adresse aus dem Abschnitt "Kontaktdaten Kunde". Du kannst auch eine andere E-Mail-Adresse eintragen.</p><p>Du musst den Zugangslink zusätzlich direkt an jeden einzelnen Teilnehmenden verschicken? Wähle dafür die Option "Wird zusätzlich an die Teilnehmenden verschickt". (Hinweis: Der Versand ist ein manueller Prozess.)</p>`,
            sendToParticipants: 'Wird zusätzlich an die Teilnehmenden verschickt',
            shipping: 'Versand',
            shippingHint:
              'Die Zugangslinks müssen zusätzlich direkt an jeden einzelnen Teilnehmenden verschickt werden. E-Mail-Adressen werden aus der Teilnehmendenliste gezogen.',
            participantError: {
              hint: 'Hinweis',
              text: 'Damit wir die Unterlagen direkt verschicken können, werden Sie im Abschnitt "Teilnehmende" aufgefordert, die E-Mail-Adressen der Teilnehmenden anzugeben.',
            },
          },
          time: 'Veranstaltungszeit',
          tool: 'Tool: {{tool}}',
          responsibility: 'Verantwortlichkeit: {{responsibility}}',
          timeHintReadOnly:
            'Hier wird das Datum und die Uhrzeit aus SAP angezeigt. Zum Ändern dieser Daten müssen sie in SAP angepasst werden.',
          eventLocationHintReadOnly:
            'Die vorgeschlagene Adresse entspricht der eingetragene Adresse im Abschnitt "Kontaktdaten Kunde".',
          locationHint: {
            customAddress:
              'Deine Anpassung gilt nur für den Veranstaltungsort. Die eingetragene Adresse im Abschnitt "Kontaktdaten Kunde" ändert sich nicht.',
            debitorAddress:
              'Die vorgeschlagene Adresse entspricht der eingetragene Adresse im Abschnitt "Kontaktdaten Kunde". Du kannst die Adresse des Veranstaltungsorts hier anpassen.',
          },
          locationNotes: 'Hinweise zum Veranstaltungsort',
          locationNotesHint: 'Kann vom Kunden ausgefüllt werden.',
          eventTool: 'Veranstaltungstool',
          eventToolHint:
            'Hier wird das Veranstaltungstool aus SAP angezeigt. Zum Ändern des Tools muss dieses in SAP angepasst werden.',
          akaTool: 'Haufe Akademie',
          clientTool: 'Kunde',
          eventLocationHint: {
            customAddress:
              'Deine Anpassung gilt nur für den Veranstaltungsort. Die eingetragene Adresse im Abschnitt "Kontaktdaten Kunde" ändert sich nicht.',
            debitorAddress:
              'Die vorgeschlagene Adresse entspricht der eingetragene Rechhnungsadresse im Abschnitt "Kontaktdaten Kunde". Du kannst die Adresse des Veranstaltungsorts hier anpassen.',
          },
          customEventLocationHint:
            'Die vorgeschlagene Adresse entspricht der Rechnungsadresse des Kunden. Du kannst die Adresse des Veranstaltungsorts hier anpassen, die Anpassung gilt nur für diese Adresse. Die Rechnungsadresse ändert sich dadurch nicht.',
        },
        trainerAccommodation: {
          label: 'Übernachtung Trainer:in',
          bookingBy: 'Die Übernachtung wird gebucht von',
          bookingByHint:
            'Bitte gib hier an, von wem die Übernachtung des Trainers, der Trainerin organisiert werden soll.',
          bookingByHintReadOnly:
            'Bitte gib hier an, von wem die Übernachtung des Trainers, der Trainerin organisiert werden soll.',
          hotelHint: 'Falls bekannt, bitte hier Hotelname und Hoteladresse eintragen.',
          hotelHintReadOnly: 'Falls bekannt, bitte hier Hotelname und Hoteladresse eintragen.',
          hotelName: 'Hotel',
          hotelSuggestion: 'Hotelempfehlung',
          akademie: 'Haufe Akademie',
          orgAkademie: 'Veranstaltungsorganisation der Haufe Akademie',
          notnecessary: 'Für den:die Trainer:in wird keine Buchung benötigt',
          contactPerson: 'Ansprechpartner:in',
          customer: 'Kunde',
          hotelLocation: 'Übernachtungsort',
        },
        roomSetup: {
          label: 'Raumausstattung',
          neededRoomSetupLabel: 'Benötigte Raumausstattung',
          roomSetupLabel: 'Raumausstattung',
          roomSetupHint:
            'Bitte wähle hier die gewünschte Raumausstattung für den Veranstaltungsort aus. Der Raum wird anhand der ausgewählten Optionen frühzeitig vorbereitet.',
          equipmentSpecificsHint:
            'Bitte vermerke hier Besonderheiten zur Raumausstattung und sonstigen Materialien (z.B. Raumgröße, Bestuhlung, etc.)',
          equipmentSpecificsLabel: 'Besonderheiten zur Ausstattung',
          notesHint: 'Anmerkung des Kunden zur benötigten Ausstattung vor Ort',
          notesLabel: 'Anmerkung zur Ausstattung',
          equipment: {
            formView: {
              U_SHAPED_TABLES: 'Raumgestaltung in U-Form',
              SIT_IN_CIRCLE: 'Raumgestaltung: Stuhlkreis ohne Tische',
              PARLAMENT: 'Raumgestaltung parlamentarisch',
              ADDITIONAL_SPACE_FOR_GROUP_WORK: 'Zusätzlicher Platz für Gruppenarbeiten (im Raum)',
              SEPARATE_ROOM_FOR_GROUP_WORK: 'Separater Raum für Gruppenarbeiten',
              PROJECTOR_AND_SCREEN: 'Beamer mit Leinwand',
              MODERATION_CASE: 'Moderationskoffer',
              FLIPCHART: '<count></count>x Flipchart',
              PINBOARD:
                '<count></count>x Pinnwand mit Metaplankarten (Karten weiß: <card_count_white></card_count_white> Stück / vierfarbig: <card_count_colored></card_count_colored> Stück)',
              MODERATION_PENS: '<count></count>x Moderationsstifte schwarz',
              CALCULATORS: '<count></count>x Taschenrechner für Teilnehmende',
            },
            readOnlyView: {
              U_SHAPED_TABLES_READONLY: 'Raumgestaltung in U-Form',
              SIT_IN_CIRCLE_READONLY: 'Raumgestaltung: Stuhlkreis ohne Tische',
              PARLAMENT_READONLY: 'Raumgestaltung parlamentarisch',
              ADDITIONAL_SPACE_FOR_GROUP_WORK_READONLY: 'Zusätzlicher Platz für Gruppenarbeiten (im Raum)',
              SEPARATE_ROOM_FOR_GROUP_WORK_READONLY: 'Separater Raum für Gruppenarbeiten',
              PROJECTOR_AND_SCREEN_READONLY: 'Beamer mit Leinwand',
              MODERATION_CASE_READONLY: 'Moderationskoffer',
              FLIPCHART_READONLY: '{{amount}}x Flipchart',
              PINBOARD_READONLY:
                '{{amount}}x Pinnwand mit Metaplankarten (Karten weiß: {{amount_white}} Stück / vierfarbig: {{amount_colored}} Stück)',
              MODERATION_PENS_READONLY: '{{amount}}x Moderationsstifte schwarz',
              CALCULATORS_READONLY: '{{amount}}x Taschenrechner für Teilnehmende',
            },
          },
          notes: 'Bemerkung',
        },
        seminarDocuments: {
          label: 'Seminarunterlagen',
          addRecipient: 'Empfänger:in hinzufügen',
          email: {
            eMailAdress: 'E-Mail-Adresse',
            sendToParticipants: 'Wird zusätzlich an die Teilnehmenden verschickt',
            shipping: 'Versand',
            shippingHint:
              'Seminarunterlagen müssen zusätzlich direkt an jeden einzelnen Teilnehmenden verschickt werden. E-Mail-Adressen werden aus der Teilnehmendenliste gezogen.',
            participantError: {
              hint: 'Hinweis',
              text: 'Damit wir die Unterlagen direkt verschicken können, werden Sie im Abschnitt "Teilnehmende" aufgefordert, die E-Mail-Adressen der Teilnehmenden anzugeben.',
            },
          },
          addressLabel: 'Versandadresse',
          emailRecipientsLabel: 'E-Mail-Versand',
          formatLabel: 'Format der Seminarunterlage',
          formatHint:
            'Bitte wähle hier das gewünschte Format für die Seminarunterlagen aus. Bei Live-Online-Formaten können nur digitale Seminarunterlagen auswählt werden.',
          formatHintReadOnly:
            'Bitte wähle hier das gewünschte Format für die Seminarunterlagen aus. Bei Live-Online-Formaten können nur digitale Seminarunterlagen auswählt werden.',
          additionalDocumentsHint:
            'Unter zusätzlichem Arbeitsmaterial verstehen wird die Zusendung von Material, das für die Durchführung der Veranstaltung erforderlich ist, wie zum Beispiel: Gesetzestexte oder Boxhandschuhe.',
          additionalDocumentsHintReadOnly:
            'Unter zusätzlichem Arbeitsmaterial verstehen wird die Zusendung von Material, das für die Durchführung der Veranstaltung erforderlich ist, wie zum Beispiel: Gesetzestexte oder Boxhandschuhe.',
          sendWritingMaterialsHint:
            'Unter Schreibmaterial verstehen wird die Zusendung von Stiften, Notizblöcken und Blanko-Namensschildern.',
          sendWritingMaterialsHintReadOnly:
            'Unter Schreibmaterial verstehen wird die Zusendung von Stiften, Notizblöcken und Blanko-Namensschildern.',
          formats: {
            digital: 'Digitale Seminarunterlagen',
            print: 'Gedruckte Seminarunterlagen',
            noSU: 'Keine Seminarunterlagen',
          },
          recipientAddressLabel: 'Versandadresse',
          recipientAddressHintReadonly:
            'An diese Adresse werden die Unterlagen versendet.<br/> Die vorgeschlagene Adresse entspricht der Rechnungsadresse aus dem Abschnitt "Kontaktdaten Kunde". Du kannst auch eine andere Adresse eintragen.',
          recipientEmailHintReadonly: `<p>An diese E-Mail-Adresse werden die Unterlagen versendet.</p><p>Die vorgeschlagene E-Mail-Adresse entspricht der E-Mail-Adresse aus dem Abschnitt "Kontaktdaten Kunde". Du kannst auch eine andere E-Mail-Adresse eintragen.</p><p>Du musst die digitalen Unterlagen zusätzlich direkt an jeden einzelnen Teilnehmenden verschicken? Diese Option kannst du über "Ändern" auswählen. (Hinweis: Der Unterlagenversand ist ein manueller Prozess.)</p>`,
          recipientEmailHintWrite: `<p>An diese E-Mail-Adresse werden die Unterlagen versendet.</p><p>Die vorgeschlagene E-Mail-Adresse entspricht der E-Mail-Adresse aus dem Abschnitt "Kontaktdaten Kunde". Du kannst auch eine andere E-Mail-Adresse eintragen.</p><p>Du musst die digitalen Unterlagen zusätzlich direkt an jeden einzelnen Teilnehmenden verschicken? Wähle dafür die Option "Wird zusätzlich an die Teilnehmenden verschickt". (Hinweis: Der Unterlagenversand ist ein manueller Prozess.)</p>`,
          recipientAddressHint: {
            customAddress:
              'Deine Anpassung gilt nur für die Adresse zur Zusendung von Seminarunterlagen. Die eingetragenen Rechnungsadresse im Abschnitt "Kontaktdaten Kunde" ändert sich nicht.',
            debitorAddress:
              'Die vorgeschlagene Adresse entspricht der eingetragenen Rechnungsadresse im Abschnitt "Kontaktdaten Kunde". Du kannst die Adresse für die Zusendung der Seminarunterlagen hier anpassen.',
          },
          recipientLabel: 'E-Mail-Adresse',
          removeRecipient: 'Empfänger:in entfernen',
          sendWritingMaterials: {
            sendWritingMaterialsCheckbox: 'Ja, für diese Veranstaltung wird Schreibmaterial gewünscht',
            label: 'Schreibmaterial',
            notAvailable: 'Kein Schreibmaterial',
            no: 'Nein',
            yes: 'Ja',
          },
          sendWritingMaterialsLabel: 'Schreibmaterial',

          additionalDocuments: {
            additionalDocumentsCheckbox: 'Ja, für diese Veranstaltung wird zusätzliches Arbeitsmaterial benötigt',

            label: 'Arbeitsmaterial (z.B. Hand-outs, Bücher, etc.)',
            notAvailable: 'Kein zusätzliches Arbeitsmaterial',
            yes: 'Ja',
            no: 'Nein',
          },
        },
        participants: {
          alerts: {
            deleteSuccess: 'Der:Die Teilnehmende wurde erfolgreich gelöscht.',
            updateSuccess: 'Der:Die Teilnehmende wurde erfolgreich aktualisiert.',
            createSuccess: 'Der:Die Teilnehmende wurde erfolgreich angelegt.',
            importCsvSuccess: 'Die Teilnehmenden wurden erfolgreich importiert.',
          },
          label: 'Teilnehmende',
          amountOfParticipants: 'Maximale Anzahl Teilnehmende',
          participantCountNote: 'Anmerkung zur Anzahl Teilnehmende',
          participantCountNotePlaceholder: 'Hier ist Platz für Ihre Anmerkung zur Abweichung.',
          participantCountNoteHint: 'Hier ist Platz für Ihre Anmerkung zur Abweichung.',
          listLabel: 'Liste der Teilnehmenden',
          hint: 'Die angezeigte Teilnehmendenzahl entspricht der maximalen Kapazität aus SAP. Zum Ändern der maximalen Teilnehmendenzahl, bitte die SAP Daten anpassen.',
          participantListhint: `Die hier eingetragenen Teilnehmendendaten werden direkt an die Inhouse Teilnehmendenverwaltung gesendet.<br/>
            Sollten dort schon Daten hinterlegt sein, werden diese hier angezeigt.`,
          providedByCustomer: 'Die Liste wird im Kundenfragebogen hochgeladen',
          listNotYetAvailable: 'Es wurde bisher noch keine Liste hochgeladen',
          addParticipant: 'Teilnehmende hinzufügen',
          uploadCsv: 'Teilnehmende importieren (CSV-Upload)',
          deleteUser: 'Teilnehmendendaten löschen',
          confirmDeleteUser: 'Möchtest du diese Daten wirklich löschen?',
          editor: {
            edit: 'Teilnehmende bearbeiten',
            create: 'Teilnehmende hinzufügen',
            saveAndClose: 'Speichern und schließen',
            saveAndAddMore: 'Speichern & weiteren Eintrag hinzufügen',
            update: 'Aktualisieren',
            errorCreate: 'Beim Anlegen ist ein Fehler aufgetreten.',
            errorUpdate: 'Beim Aktualisieren ist ein Fehler aufgetreten.',
            pleaseEnterValidEmailAddress: 'Bitte gib eine gültige E-Mail-Adresse ein',
          },
          csvImport: {
            downloadTemplate: 'Vorlage herunterladen (.csv)',
            chooseFile: 'Datei auswählen',
            description:
              'Bitte lade hier die Liste der Teilnehmenden hoch. Die Daten in deiner Liste werden validiert und können dann importiert werden.',
            alert: 'Nur CSV-Dateien können hochgeladen werden. Bitte nutze dafür unsere Vorlage.',
            startImport: '{{participantCount}} Teilnehmende importieren',
            title: 'CSV-Import',
            error: 'Die Teilnehmendenliste konnte nicht hochgeladen werden',
            errorFormat: 'Dieses Dateiformat ist nicht erlaubt. (Erwartet: .csv)',
            errorInvalidContent: 'Bitte korrigieren Sie die Teilnehmendendaten und laden Sie die Datei erneut hoch',
          },
          importPreview: {
            info: 'Fehler/Hinweise',
            participantExists: 'Identischer Eintrag existiert bereits',
            participantUpdate: 'Eintrag wird aktualisiert',
            errors: {
              MISSING_FIRST_NAME: 'Vorname fehlt',
              MISSING_LAST_NAME: 'Nachname fehlt',
              MISSING_EMAIL: 'E-Mail-Adresse fehlt',
              INVALID_EMAIL: 'Ungültige E-Mail-Adresse',
            },
          },
          table: {
            lastName: 'Nachname',
            firstName: 'Vorname',
            email: 'E-Mail-Adresse',
            title: 'Titel',
          },
        },
        submit: {
          dialogTitle: 'Kundenfragebogen versenden',
          dialogContent: `Mit Klick auf "Senden" erhält <br/>
          {{accessLinkASP}}<br/>
          eine E-Mail mit einem Zugangslink zum Kundenfragebogen.<br/>
          Möchtest du die E-Mail jetzt senden?`,
        },
        summary: {
          summaryAndSendLabel: 'Zusammenfassung & Kundenfragebogen senden',
          summaryLabel: 'Zusammenfassung',
          dispatch: 'Versand',
          finalNotes: 'Abschließende Informationen',
          initialData: 'Initialbefüllung',
          eventDetails: 'Details zur Veranstaltung',
          copied: 'Kopiert!',
          copyAddress: 'Adresse kopieren',
          copyLine: 'Abschnitt kopieren',
          contactData: {
            title: 'Kontaktdaten Kunde',
          },
          billing: {
            title: 'Rechnungsstellung',
            billingAdress: 'Rechnungsadresse',
            additionalData: 'Zusätzliche Angaben',
          },
          confirmationOfParticipation: {
            title: 'Digitale Teilnahmebestätigung',
            enabledTB: 'Teilnahmebestätigungen gewünscht',
            sendTo: 'Zusendung an',
          },
          feedback: {
            title: 'Feedback',
            enabledFB: 'Feedback gewünscht',
            sentTo: 'Zusendung an',
          },
          location: {
            title: {
              onsite: 'Zeit und Ort',
              online: 'Tool und Zeit',
            },
            participantCount: 'Anzahl Teilnehmende',
            notificationSendToParticipants: 'Link an TNs senden',
            time: 'Uhrzeit der Veranstaltung',
            tool: 'Tool',
            differentTime: 'Andere Uhrzeit',
            toolExpertise: {
              question: 'Haben die Teilnehmenden bereits Erfahrung im Umgang mit dem Tool?',
            },
          },
          roomSetup: {
            title: 'Raumaustattung',
            equipment: 'Equipment',
            notes: 'Bemerkung',
          },
          seminarDocuments: {
            title: 'Seminarunterlagen',
            recipientAdress: 'Versandadresse',
            recipientEmailAddresses: 'E-Mail-Adresse',
            format: 'Format',
            writingMaterials: 'Schreibmaterialien',
            notificationSendToParticipants: 'SU an TNs senden',
          },
          trainerAccommodation: {
            title: 'Übernachtung Trainer:in',
            customer: '',
            bookedBy: 'Buchung über',
            hotelSuggestion: 'Hotelempfehlung',
            hotelName: 'Hotelname',
            hotelAdress: 'Hoteladresse',
          },
          participants: {
            title: 'Teilnehmende',
          },
          notifications: {
            contactData: {
              address: {
                changed: 'Korrektur: Adresse',
              },
              extraBillingInformation: {
                changed: 'Zusatz-Info für RE geändert',
              },
              orderNumber: {
                changed: 'Bestellnummer für RE geändert',
              },
            },
            location: {
              startTime: {
                filled: 'Andere Zeit gewünscht',
              },
              address: {
                changed: 'VA-Ort weicht ab',
                customAddressChanged: 'VA-Ort wurde geändert',
              },
              recipientEmailAddresses: {
                changed: 'Mail weicht ab',
              },
              notificationSendToParticipants: 'Zugangslinks an TNs',
            },
            trainerHotel: {
              bookingBy: {
                academy: 'Hotel buchen',
              },
              hotelSuggestion: {
                filled: 'Info zum Hotel',
                changed: 'Info zum Hotel geändert',
              },
            },
            roomSetup: {
              equipment: {
                missing: 'Ausstattungsliste senden',
              },
              notes: {
                filled: 'Info zur Ausstattung',
              },
            },
            seminarDocuments: {
              sendWritingMaterials: {
                true: 'Schreibmaterial gewünscht',
              },
              recipientEmailAddresses: {
                changed: 'Mail weicht ab',
              },
              recipientAddress: {
                changed: 'Versandadresse weicht ab',
                customAddressChanged: 'Adresse wurde geändert',
              },
            },
            confirmationOfParticipation: {
              enabled: {
                true: 'TBs gewünscht',
              },
              recipientEmailAddresses: {
                changed: 'Mail weicht ab',
              },
            },
            participants: {
              participantCount: {
                filled: 'Anzahl geändert',
              },
              participantCountNote: {
                filled: 'Info zur Anzahl',
              },
              participants: {
                missingData: 'Kunde muss TN-Liste senden',
                unacceptableAmount: 'Anzahl TN klären',
              },
            },
          },
          finalInformation: {
            header: 'Kundenanmerkung',
            label: 'Möchten Sie uns noch etwas mitteilen?',
          },
        },
      },
    },
  },
  feedbackResultLink: {
    tableHeaders: {
      recipient: 'Empfänger:in',
      eventTitle: 'Veranstaltungstitel',
      eventId: 'Veranstaltungs-ID',
      eventStartDate: 'Veranstaltungsbeginn',
      eventEndDate: 'Veranstaltungsende',
      createdAt: 'Erstellt am',
      validUntil: 'Gültig bis',
    },
    showPreview: 'Vorschau',
    copyLink: 'Link kopieren',
    sendMail: 'E-Mail versenden',
    feedbackResults: 'Feedbackauswertung',
    confirmDeleteLink: 'Möchtest du diese Feedback-Seite wirklich löschen?',
    sendMailLinkDialogTitle: 'Link per Mail verschicken',
    internalEmail: 'Ein Versand an interne E-Mail-Adressen ist nicht möglich.',
    invalidEmail: 'Eingabe enstpricht keinem gültigen E-Mail-Format',
    recipientEmail: 'Empfänger:innen E-Mail-Adresse(n) (mehrere E-Mail-Adressen durch Komma trennen)',
    eventId: 'Veranstaltungs-ID',
    copiedLink: 'Link kopiert',
    headerLinks: 'Feedbackauswertung - Zugangslinks',
    eventWithoutAnswers: 'Zu dieser Veranstaltung wurde kein Feedback abgegeben',
    invalidBusinessUnit: 'Ungültiger Geschäftsbereich ({{businessUnit}}) - muss ILS oder IHC sein',
    linkAlreadySent: 'Der Link wurde bereits an diese Empfänger gesendet',
    noLinkData: 'Keine Feedbackauswertung',
    eventNotFound: 'Zur Verantstaltungs-ID {{eventId}} wurde keine Veranstaltung gefunden',
    sendMailLinkDialog: {
      recipientsDescription:
        'Eine E-Mail mit dem Zugangslink zum Feedback der Veranstaltung <strong>"{{eventTitle}} - ({{eventId}})"</strong> wird an folgende E-Mail-Adressen geschickt:',
    },
  },
  event: {
    breadcrumb: {
      configuration: 'Konfiguration',
    },
    overview: {
      mainHeading: 'Status-Übersicht',
      statusLabel: 'Status:',
      configuration: {
        header: 'VA-Konfiguration',
        gotoConfiguration: 'Zur VA-Konfiguration',
        notSent: 'Nicht versendet',
        sent: 'Kundenfragebogen versendet',
        sentAt: 'Gesendet am:',
      },
      feedback: {
        overallImpression: 'Gesamteindruck:',
        gotoFeedbackResults: 'Zur Feedbackauswertung',
        header: 'Feedback',
        linkValidTo: 'Zugangslink gültig bis:',
        nps: 'NPS:',
        recipient: 'Kundenansprechpartner:in:',
        scheduledAt: 'Wird versendet am:',
        sentAt: 'An Kundenansprechpartner:in gesendet am:',
        status: {
          DISABLED: 'Feedback deaktiviert',
          NOT_SENT_DISABLED: 'Zugangslink zur Feedbackauswertung nicht versendet - Feedback deaktiviert',
          NOT_SENT_NO_FEEDBACK: 'Zugangslink zur Feedbackauswertung nicht versendet - kein Feedback eingegangen',
          NOT_SENT_NO_RECIPIENT: 'Zugangslink zur Feedbackauswertung nicht versendet - kein Empfänger eingetragen',
          NOT_SENT_YET: 'Feedback aktiviert',
          SCHEDULED: 'Versand des Zugangslinks zur Feedbackauswertung geplant',
          SENT: 'Zugangslink zur Feedbackauswertung versendet',
        },
      },
      kundenfragebogen: {
        contactPerson: 'Kundenansprechpartner:in:',
        deadline: 'Abgabedatum:',
        gotoSummary: 'Zur Zusammenfassung',
        header: 'Kundenfragebogen',
        receivedFromCustomerAt: 'Von Kundenansprechpartner:in erhalten am:',
        sentToCustomerAt: 'An Kundenansprechpartner:in gesendet am:',
        status: {
          COMPLETED_BY_CUSTOMER: 'Kundenfragebogen von Kundenansprechpartner:in ausgefüllt und zurückgesendet',
          NOT_SENT_TO_CUSTOMER: 'Nicht versendet',
          PENDING: 'Liegt bei Kundenansprechpartner:in',
        },
      },
    },
  },
  delete: 'Löschen',
  cancel: 'Abbrechen',
  close: 'Schließen',
  send: 'Senden',
  edit: 'Ändern',
  save: 'Speichern',
  searchLabel: 'Suche ...',
  dateFormat: 'dd.MM.yyyy hh:mm',
  true: 'Ja',
  false: 'Nein',
  undefined: '',
  null: '',
};
