/* eslint-disable no-nested-ternary */
import { FunctionComponent, useState } from 'react';
import Section from '../Common/Section';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import LocationOnsiteForm from './LocationOnsiteForm';
import LocationOnlineReadonly from './LocationOnlineReadonly';
import LocationOnsiteReadonly from './LocationOnsiteReadonly';
import { t } from 'i18next';
import LocationOnlineForm from './LocationOnlineForm';

const Location: FunctionComponent = () => {
  const [readonly, setReadonly] = useState(true);
  const { event } = useEventSettings();

  if (!event) return <></>;

  const title = t(`eventDetails.settings.tabs.location.${event.isOnline ? 'online' : 'onsite'}`);

  if (event.isOnline) {
    return (
      <Section setReadonly={setReadonly} tabId="location" readonly={readonly} title={title}>
        {readonly ? (
          <LocationOnlineReadonly
            ablauf={event.ablauf}
            tool={event.location.name}
            isClientTool={event.location.isClientTool}
          />
        ) : (
          <LocationOnlineForm
            ablauf={event.ablauf}
            tool={event.location.name}
            isClientTool={event.location.isClientTool}
          />
        )}
      </Section>
    );
  } else {
    return (
      <Section setReadonly={setReadonly} tabId="location" readonly={readonly} title={title}>
        {readonly ? <LocationOnsiteReadonly ablauf={event.ablauf} /> : <LocationOnsiteForm ablauf={event.ablauf} />}
      </Section>
    );
  }
};

export default Location;
