import { Alert, Box, Grid, Paper } from '@mui/material';
import { FunctionComponent } from 'react';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { ColorPalette } from '@hmg-aka-lu/elevate-theme';

export const NOTIFICATION_TYPE = ['TASK', 'INFO'] as const;
export type NotificationType = (typeof NOTIFICATION_TYPE)[number];

export interface ISummaryNotification {
  id: string;
  text: string;
  notificationType?: NotificationType;
}
interface ISectionBlockProps {
  children: React.ReactNode | React.ReactNode[];
  notification?: ISummaryNotification[];
  'data-testid'?: string;
}

const SectionBlock: FunctionComponent<ISectionBlockProps> = ({ children, notification, 'data-testid': dataTestId }) => {
  const getInfoIconMapping = (notification: ISummaryNotification) => {
    switch (notification?.notificationType) {
      case NOTIFICATION_TYPE[0]:
        return <ScheduleIcon sx={{ color: ColorPalette.surface.accent }} />;
      case NOTIFICATION_TYPE[1]:
        return <NoteAltIcon sx={{ color: ColorPalette.surface.brand }} />;
      default:
        break;
    }
  };

  return (
    <Box
      data-testid={dataTestId}
      sx={{
        '& + &': {
          mt: 3,
        },
      }}
    >
      <Grid container>
        <Grid item xs={12} md={notification ? 8 : 12}>
          {children}
        </Grid>
        {notification && notification.length > 0 && (
          <Grid item xs={12} md={4}>
            {notification.map((item) => {
              return (
                <Alert
                  data-testid={`${dataTestId}-${item.id}-notification`}
                  key={`${dataTestId}-${item.id}-notification`}
                  component={Paper}
                  elevation={1}
                  variant="outlined"
                  severity="info"
                  iconMapping={{
                    info: getInfoIconMapping(item),
                  }}
                  sx={{
                    bgcolor: ColorPalette.surface.background,
                    border: 0,
                    mt: 2,
                  }}
                >
                  {item.text}
                </Alert>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SectionBlock;
