import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { DefaultLabel } from '../Common/DefaultLabel';
import ParticipantsTable from './ParticipantsTable';
import SectionBlock from '../Common/SectionBlock';
import { Box } from '@mui/system';
import { ReadonlyField } from '../Common/ReadonlyField';

const ParticipantsForm: FunctionComponent<unknown> = () => {
  const { t } = useTranslation();

  const { event, eventSettings } = useEventSettings();

  if (!eventSettings || !event) return <></>;
  const participantCount = eventSettings.participants?.participantCount ?? '';
  const maxEventCapacity = event.capacity?.max ?? '';

  return (
    <Box>
      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.participants.amountOfParticipants')}
          alertText={t('eventDetails.settings.tabs.participants.hint')}
        />
        {maxEventCapacity ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          alertTitle={t('generalHintTitle')}
          label={t('eventDetails.settings.tabs.participants.plannedAmountOfParticipants')}
          alertText={t('eventDetails.settings.tabs.participants.plannedAmountHint')}
        />
        {participantCount ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
      </SectionBlock>

      <SectionBlock>
        <DefaultLabel
          label={t(`eventDetails.settings.tabs.participants.listLabel`)}
          alertTitle={t('generalHintTitle')}
          alertText={<Trans i18nKey={'eventDetails.settings.tabs.participants.participantListhint'} />}
        />
        <ParticipantsTable enableEdit={true} />
      </SectionBlock>
    </Box>
  );
};

export default ParticipantsForm;
