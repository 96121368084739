import { Box, Button, FormControl, IconButton, Tooltip } from '@mui/material';
import { ADDRESS_SECTIONS, IAddress } from '@shared/interfaces';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddressFormDialog from './AddressFormDialog';
import AddressReadOnly from './AddressReadOnly';

export interface ISelectableAddress {
  address: Partial<IAddress>;
  key: ADDRESS_SECTIONS;
  label: string;
}

interface IAddressProps {
  selectableAddresses: ISelectableAddress[];
  customAddress?: Partial<IAddress>;
  onChangeCustomAddress: (address: Partial<IAddress> | undefined) => void;
  dialogTitle?: string;
}

const AddressMultiForm: FunctionComponent<IAddressProps> = (props) => {
  const { t } = useTranslation();

  const [showCustomAddressForm, setShowCustomAddressForm] = useState(false);

  return (
    <>
      <FormControl data-testid={`address-list`}>
        {props.customAddress ? (
          <Box display={'flex'}>
            <Box data-testid="custom-address-container">
              <AddressReadOnly data-testid="custom-address" address={props.customAddress} />
            </Box>
            <Tooltip title={t('address.editAddress')}>
              <IconButton
                data-testid="edit-custom-address"
                onClick={() => setShowCustomAddressForm(true)}
                sx={{ alignSelf: 'center', marginLeft: 2 }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Button
            data-testid="add-new-address-button"
            onClick={() => setShowCustomAddressForm(true)}
            sx={{ mt: 2 }}
            startIcon={<AddIcon />}
          >
            {t('address.addNewAddress')}
          </Button>
        )}
      </FormControl>

      {showCustomAddressForm && (
        <AddressFormDialog
          address={props.customAddress}
          openDialog={setShowCustomAddressForm}
          onChange={props.onChangeCustomAddress}
          selectableAddresses={props.selectableAddresses}
          dialogTitle={props.dialogTitle}
        />
      )}
    </>
  );
};

export default AddressMultiForm;
