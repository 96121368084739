import { FunctionComponent, useContext } from 'react';
import SectionSummary from '../Common/SectionSummary';
import useSectionVisibility from '../../../../hooks/useSectionVisibility';
import { useTranslation } from 'react-i18next';
import { IEventSettingsData } from '@shared/interfaces';
import { Typography } from '@mui/material';
import { useEventSettings } from '../../../Context/EventSettingsContext';
import { useUsersList } from '../../../../queries/users';
import JWTContext from '../../../JWTContext';
import AddressReadOnly from '../Common/AddressReadOnly';
import SectionBlock, { ISummaryNotification, NOTIFICATION_TYPE } from '../Common/SectionBlock';
import { SummaryLabel } from '../Common/SummaryLabel';
import { ReadonlyField } from '../Common/ReadonlyField';
import deepEqual from 'deep-equal';
import { getAddressForCopy } from '../Common/getAddressCopy';

const ContactDataSummary: FunctionComponent = () => {
  const sectionId: keyof IEventSettingsData = 'contactData';
  const { isVisible } = useSectionVisibility(sectionId);
  const { t } = useTranslation();

  const { getJWT } = useContext(JWTContext);
  const { eventSettings, debitorId, snapshots } = useEventSettings();
  const { data: users } = useUsersList(getJWT, debitorId);

  if (!eventSettings) {
    return <></>;
  }

  const recipients = eventSettings.contactData?.contactPersonsCustomer ?? [];

  const { orderNumber, extraBillingInformation, customerAddress } = eventSettings.contactData ?? {};
  const { contactData: sentToCustomerContactData } =
    snapshots.find((snapshot) => snapshot.snapshotType === 'SENT_TO_CUSTOMER') ?? {};
  const { contactData: finishedByCustomerContactData } =
    snapshots.find((snapshot) => snapshot.snapshotType === 'FINISHED_BY_CUSTOMER') ?? {};

  const getCustomerAddressNotification = (): ISummaryNotification[] | undefined => {
    if (!deepEqual(sentToCustomerContactData?.customerAddress, finishedByCustomerContactData?.customerAddress)) {
      return [
        {
          id: 'address-changed',
          text: t('eventDetails.settings.tabs.summary.notifications.contactData.address.changed'),
          notificationType: NOTIFICATION_TYPE[1],
        },
      ];
    }
    return undefined;
  };

  const getExtraBillingInformationNotification = (): ISummaryNotification[] | undefined => {
    if (sentToCustomerContactData?.extraBillingInformation !== finishedByCustomerContactData?.extraBillingInformation) {
      return [
        {
          id: 'extraBillingInformation-changed',
          text: t('eventDetails.settings.tabs.summary.notifications.contactData.extraBillingInformation.changed'),
          notificationType: NOTIFICATION_TYPE[1],
        },
      ];
    }

    return undefined;
  };

  const getOrderNumberNotification = (): ISummaryNotification[] | undefined => {
    if (sentToCustomerContactData?.orderNumber !== finishedByCustomerContactData?.orderNumber) {
      return [
        {
          id: 'orderNumber-changed',
          text: t('eventDetails.settings.tabs.summary.notifications.contactData.orderNumber.changed'),
          notificationType: NOTIFICATION_TYPE[1],
        },
      ];
    }

    return undefined;
  };

  return (
    <SectionSummary title={t(`eventDetails.settings.tabs.summary.${sectionId}.title`)} hidden={!isVisible}>
      <SectionBlock>
        <SummaryLabel label={t('eventDetails.settings.tabs.contactData.contactPersonLabel')} />
        {recipients?.map((recipient) => {
          const user = users.find((item) => item.id === recipient);
          return (
            <Typography key={`recipient-${user?.emailAddress}`}>
              {user?.firstName} {user?.lastName} - {user?.emailAddress}
            </Typography>
          );
        })}
      </SectionBlock>

      <SectionBlock data-testid="contact-data-address" notification={getCustomerAddressNotification()}>
        <SummaryLabel
          label={t('eventDetails.settings.tabs.contactData.billingAddressLabel')}
          copyText={
            customerAddress
              ? getAddressForCopy({ ...customerAddress, country: t(`countries.${customerAddress.country}`) })
              : undefined
          }
        />
        <AddressReadOnly address={customerAddress} />
      </SectionBlock>

      <SectionBlock data-testid="contact-data-order-number" notification={getOrderNumberNotification()}>
        <SummaryLabel label={t('eventDetails.settings.tabs.contactData.orderNumberShort')} copyText={orderNumber} />
        {orderNumber ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
      </SectionBlock>

      <SectionBlock
        data-testid="contact-data-extra-billing-information"
        notification={getExtraBillingInformationNotification()}
      >
        <SummaryLabel
          label={t('eventDetails.settings.tabs.contactData.extraBillingInformationLabel')}
          copyText={
            extraBillingInformation
              ? t('eventDetails.settings.tabs.contactData.extraBillingInformationLabel') +
                ': ' +
                extraBillingInformation
              : undefined
          }
        />
        {extraBillingInformation ?? <ReadonlyField variant="EMPTY" text={t('noData')} />}
      </SectionBlock>
    </SectionSummary>
  );
};

export default ContactDataSummary;
