type EnvironmentSpecificConfig = {
  API_URL: string;
  BLOCKED_RECIPIENT_DOMAINS: string[];
  LOS_COCKPIT_URL: string;
  ORGANIZATION_COCKPIT_URL: string;
};

const dev: EnvironmentSpecificConfig = {
  API_URL: 'http://localhost:8001/dev',
  BLOCKED_RECIPIENT_DOMAINS: [],
  LOS_COCKPIT_URL: 'https://cockpit.lu.akadev.de',
  ORGANIZATION_COCKPIT_URL: 'http://localhost:4001/',
};
const test: EnvironmentSpecificConfig = {
  API_URL: 'https://vhu1g5bgnj-vpce-0cc31765e64fffec7.execute-api.eu-central-1.amazonaws.com/test',
  BLOCKED_RECIPIENT_DOMAINS: [],
  LOS_COCKPIT_URL: 'https://cockpit.lu.akadev.de',
  ORGANIZATION_COCKPIT_URL: 'https://cockpit.organizations.lu.ban.akadev.de/',
};

const prod: EnvironmentSpecificConfig = {
  API_URL: 'https://4s31qhbar0-vpce-04301e6b4f30d6e3d.execute-api.eu-central-1.amazonaws.com/prod',
  BLOCKED_RECIPIENT_DOMAINS: ['haufe-akademie.de', 'haufe.com'],
  LOS_COCKPIT_URL: 'https://cockpit.lernumgebung.haufe-akademie.de',
  ORGANIZATION_COCKPIT_URL: 'https://mein.cockpit.haufe-akademie.de/',
};

const env = process.env.REACT_APP_STAGE || 'dev';

export const MSTEAM_SUPPORT_CHANNEL =
  'https://teams.microsoft.com/l/channel/19%3A2gvpHUXEWfQJLt6LSFV7JN3yzrel27trZ6lTizEe5eI1%40thread.tacv2/Support%20Inhouse-Cockpit?groupId=fa59677c-c4ef-4bd4-a887-754f4bb0b261&ngc=true&allowXTenantAccess=true';

export const STAGE = env;

const mergedConfig = {
  ...(env === 'dev' && dev),
  ...(env === 'test' && test),
  ...(env === 'prod' && prod),
  STANDALONE: env === 'dev',
};

export const MICROFRONTEND_ID = 'organizations';

export default mergedConfig;

export const DATE_FORMAT = 'dd.MM.yyyy HH:mm';
export const DATE_FORMAT_SHORT = 'dd.MM.yyyy';

export const EMAIL_REGEX =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]{1,}(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]{1,})*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9]{1,2})\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9]{1,2}?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
